import React, { useCallback, useEffect, useRef, useState } from "react";


import {
  colors
} from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { BEREdited } from "common/components/BEREdited";
import {
  DwellingAndScenarioPerformanceBanner
} from "common/components/DwellingAndScenarioPerformanceBanner";
import iconTitleHomeBlank from "common/icons/icon-title-home-blank.png";
import {
  useHomeEnergyAssessment
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Dwelling } from "features/dwellings/types";
import type { MinimalProjectDwelling } from "features/projects/types";


interface HomeEnergyAssessmentDialogContent4Props {
  dwelling: Dwelling | MinimalProjectDwelling;
  contentBackgroundColour: string;
  pageBackgroundColour: string;
  scenarioDetail: string;
  scenarioRating: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File,
    field: string
  ) => void;
}


export function DialogContentCurrent(
  {
    dwelling,
    contentBackgroundColour,
    pageBackgroundColour,
    scenarioDetail,
    scenarioRating,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent4Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingCurrentEnergy,
    setSectionHeadingCurrentEnergy
  ] = useState<string>("");

  const [
    sectionHeadingCurrentEnergyError,
    setSectionHeadingCurrentEnergyError
  ] = useState<boolean>(false);

  const [
    sectionHeadingCurrentEnergyLength,
    setSectionHeadingCurrentEnergyLength
  ] = useState<number>(0);

  const sectionHeadingCurrentEnergyLengthLimit = 100;

  const sectionHeadingCurrentEnergyInputRef = useRef<HTMLInputElement>();

  const [
    currentEnergyParagraph1,
    setCurrentEnergyParagraph1
  ] = useState<string>("");

  const [
    currentEnergyParagraph1Error,
    setCurrentEnergyParagraph1Error
  ] = useState<boolean>(false);

  const [
    currentEnergyParagraph1Length,
    setCurrentEnergyParagraph1Length
  ] = useState<number>(0);

  const currentEnergyParagraph1LengthLimit = 500;

  const currentEnergyParagraph1InputRef = useRef<HTMLInputElement>();

  const [
    currentEnergyParagraph2,
    setCurrentEnergyParagraph2
  ] = useState<string>("");

  const [
    currentEnergyParagraph2Error,
    setCurrentEnergyParagraph2Error
  ] = useState<boolean>(false);

  const [
    currentEnergyParagraph2Length,
    setCurrentEnergyParagraph2Length
  ] = useState<number>(0);

  const currentEnergyParagraph2LengthLimit = 500;

  const theme = useTheme();

  useEffect(
    () => {

      if (homeEnergyAssessment !== undefined) {
        setSectionHeadingCurrentEnergy(
          homeEnergyAssessment.section_heading_current_energy
        );

        setSectionHeadingCurrentEnergyLength(
          homeEnergyAssessment.section_heading_current_energy !== null ?
            homeEnergyAssessment.section_heading_current_energy.length : 0
        );

        setCurrentEnergyParagraph1(
          homeEnergyAssessment.current_energy_paragraph_1
        );

        setCurrentEnergyParagraph1Length(
          homeEnergyAssessment.current_energy_paragraph_1 !== null ?
            homeEnergyAssessment.current_energy_paragraph_1.length : 0
        );

        setCurrentEnergyParagraph2(
          homeEnergyAssessment.current_energy_paragraph_2
        );

        setCurrentEnergyParagraph2Length(
          homeEnergyAssessment.current_energy_paragraph_2 !== null ?
            homeEnergyAssessment.current_energy_paragraph_2.length : 0
        );
      }


    },
    [
      homeEnergyAssessment
    ]
  );

  useEffect(
    () => {

      if (
        currentEnergyParagraph1Error ||
        currentEnergyParagraph2Error
      ) {
        setInputError(true);
      }

      if (inputError) {
        if (
          !currentEnergyParagraph1Error &&
          !currentEnergyParagraph2Error
        ) {
          setInputError(false);
        }
      }

    },
    [
      currentEnergyParagraph1Error,
      currentEnergyParagraph2Error,
      inputError,
      setInputError
    ]
  );

  const handleFieldBlur = useCallback(
    (value: string | number | File, field: string) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconTitleHomeBlank}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingCurrentEnergyError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingCurrentEnergy}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldBlur(
                  event.target.value,
                  "section_heading_current_energy"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingCurrentEnergyLength(value.length);
              if (value.length > sectionHeadingCurrentEnergyLengthLimit) {
                setSectionHeadingCurrentEnergyError(true);
              } else if (sectionHeadingCurrentEnergyError) {
                setSectionHeadingCurrentEnergyError(false);
              }
              setSectionHeadingCurrentEnergy(value);
            }}
            helperText={
              sectionHeadingCurrentEnergyLengthLimit - sectionHeadingCurrentEnergyLength <= (sectionHeadingCurrentEnergyLengthLimit / 2) ?
                `${sectionHeadingCurrentEnergyLength}/${sectionHeadingCurrentEnergyLengthLimit}` : ""
            }
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                if (!inputError) {
                  if (
                    currentEnergyParagraph1InputRef !== undefined &&
                    currentEnergyParagraph1InputRef.current !== undefined
                  ) {
                    currentEnergyParagraph1InputRef.current.focus();
                  }
                }
              }
            }}
            inputRef={sectionHeadingCurrentEnergyInputRef}
          />

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <DwellingAndScenarioPerformanceBanner
          dwellingDetail={String(dwelling.mprn)}
          dwellingRating={dwelling.performance.rating}
          scenarioDetail={scenarioDetail}
          scenarioRating={scenarioRating}
        />

      </Box>

      {
        dwelling.performance.ber_edited ? <BEREdited/> : null
      }

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={currentEnergyParagraph1Error}
          label="Baseline"
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={currentEnergyParagraph1}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldBlur(
                event.target.value,
                "current_energy_paragraph_1"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target;
            setCurrentEnergyParagraph1Length(value.length);
            if (value.length > currentEnergyParagraph1LengthLimit) {
              setCurrentEnergyParagraph1Error(true);
            } else if (currentEnergyParagraph1Error) {
              setCurrentEnergyParagraph1Error(false);
            }
            setCurrentEnergyParagraph1(value);
          }}
          helperText={
            currentEnergyParagraph1LengthLimit - currentEnergyParagraph1Length <= (currentEnergyParagraph1LengthLimit / 2) ?
              `${currentEnergyParagraph1Length}/${currentEnergyParagraph1LengthLimit}` : ""
          }
          inputRef={currentEnergyParagraph1InputRef}
        />

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <Tooltip
          title={
            "This field is not currently editable. We're working on it."
          }
        >
          <TextField
            disabled
            error={currentEnergyParagraph2Error}
            label="Current state"
            sx={{
              backgroundColor: pageBackgroundColour,
              width: "100%"
            }}
            multiline
            size="small"
            variant="outlined"
            InputProps={{
              sx: {
                backgroundColor: theme.palette.common.white
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right"
              }
            }}
            value={currentEnergyParagraph2}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldBlur(
                  event.target.value,
                  "current_energy_paragraph_2"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setCurrentEnergyParagraph2Length(value.length);
              if (value.length > currentEnergyParagraph2LengthLimit) {
                setCurrentEnergyParagraph2Error(true);
              } else if (currentEnergyParagraph2Error) {
                setCurrentEnergyParagraph2Error(false);
              }
              setCurrentEnergyParagraph2(value);
            }}
            helperText={
              currentEnergyParagraph2LengthLimit - currentEnergyParagraph2Length <= (currentEnergyParagraph2LengthLimit / 2) ?
                `${currentEnergyParagraph2Length}/${currentEnergyParagraph2LengthLimit}` : ""
            }
          />
        </Tooltip>

      </Box>


    </DialogContent>
  );
}
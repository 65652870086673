import { useEffect, useState } from "react";

import {
  useLazyGetProjectDwellingsQuery
} from "features/projects/api/projectsApi";

import type { Dwelling } from "features/dwellings/types";
import type { MinimalProjectDwelling } from "features/projects/types";


export function useProjectDwellingsTable() {

  const [
    totalCount,
    setTotalCount
  ] = useState<number>(0);

  const [
    projectDwellingsLoading,
    setProjectDwellingsLoading
  ] = useState(false);

  const [
    dwellingsInProject,
    setDwellingsInProject
  ] = useState<Array<Dwelling | MinimalProjectDwelling>>([]);

  const [
    getDwellingsInProject,
    {
      data: paginatedDwellingsInProject,
      isFetching: isGetProjectDwellingsFetching,
      isLoading: isGetProjectDwellingsLoading
    }
  ] = useLazyGetProjectDwellingsQuery();

  useEffect(
    () => {
      setProjectDwellingsLoading(
        isGetProjectDwellingsFetching ||
        isGetProjectDwellingsLoading
      );
    }, [
      isGetProjectDwellingsFetching,
      isGetProjectDwellingsLoading
    ]
  );

  useEffect(
    () => {
      setDwellingsInProject(
        paginatedDwellingsInProject?.results ?? []
      );
      setTotalCount(
        paginatedDwellingsInProject?.count ?? 0
      );
    }, [
      paginatedDwellingsInProject
    ]
  );

  return {
    getDwellingsInProject,
    projectDwellingsLoading,
    dwellingsInProject,
    totalCount
  };
}

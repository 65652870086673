import type {
  Dispatch,
  SetStateAction
} from "react";
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef
} from "react";

import MaterialTable, { MTableToolbar } from "@material-table/core";
import { toast } from "react-toastify";

import {
  Add as AddIcon,
  Assessment as AssessmentIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Euro as EuroIcon,
  Compare as CompareIcon,
  TableView as TableViewIcon,
  PictureAsPdf as PdfIcon,
  Person as PersonIcon,
  Groups as GroupsIcon,
  Search as SearchIcon
} from "@mui/icons-material";
import { colors, Select, TablePagination, Zoom } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import {
  BERChevron
} from "common/components/BERChevron";
import {
  ConfirmationDialog
} from "common/components/ConfirmationDialog";
import {
  DWELLING_TABLE_COLUMNS
} from "common/constants/tables";
import {
  ProjectCostsDialog
} from "features/projectCosts/dialogs/ProjectCostsDialog";
import {
  AddDwellingsToProjectDialog
} from "features/projects/dialogs/AddDwellingsToProjectDialog";
import {
  useProjectDwellingsTable
} from "features/projects/hooks/useProjectDwellingsTable";

import type {
  Column
} from "@material-table/core";
import type { Dwelling } from "features/dwellings/types";
import type {
  CreateOrGetHEAApiRequest
} from "features/homeEnergyAssessment/api/types";
import type {
  UpdateProjectDwellingsRequest
} from "features/projects/api/types";
import type {
  MinimalProjectDwelling,
  ProjectReportConfig,
  ProjectScenarioRecommendation
} from "features/projects/types";
import type {
  ProjectScenario
} from "features/projectScenarios/types";
import type {
  CreateTAFApiRequest
} from "features/technicalAssessmentForm/api/types";


interface ProjectDwellingsTableProps {
  handleUpdateProjectScenarioRecommendation: (
    requestArgs: {
      id: number;
      dwelling_type: string;
      scenario_id: number;
      dwelling_id: number
    }
  ) => void;
  isProjectRunning: boolean;
  onClickGeneratePDFReport: (dwelling: Dwelling | MinimalProjectDwelling) => void;
  onClickScenarioComparison: (dwelling: Dwelling | MinimalProjectDwelling) => void;
  onRemoveDwellings: (dwellings: Array<Dwelling | MinimalProjectDwelling>) => void;
  projectId: number;
  projectTitle: string;
  projectReportConfig: ProjectReportConfig;
  projectScenarios: Array<ProjectScenario>;
  scenarioRecommendations: Array<ProjectScenarioRecommendation>;
  selectedDwellings: Dwelling[] | MinimalProjectDwelling[];
  setSelectedDwellings: Dispatch<SetStateAction<Dwelling[] | MinimalProjectDwelling[]>>;
  setShowCompareScenariosModal: Dispatch<SetStateAction<boolean>>;
  onCreateOrGetHeaClicked: (
    request: CreateOrGetHEAApiRequest,
    dwelling: Dwelling | MinimalProjectDwelling,
    recommendedScenario: ProjectScenario
  ) => void;
  onCreateTafClicked: (request: CreateTAFApiRequest) => void;
  onUpdateDwellings: (
    data: Pick<UpdateProjectDwellingsRequest, "add" | "remove">
  ) => void;
}

export function ProjectDwellingsTable(
  {
    handleUpdateProjectScenarioRecommendation,
    isProjectRunning,
    onRemoveDwellings,
    onClickGeneratePDFReport,
    onClickScenarioComparison,
    projectId,
    projectTitle,
    projectReportConfig,
    projectScenarios,
    scenarioRecommendations,
    selectedDwellings,
    setSelectedDwellings,
    setShowCompareScenariosModal,
    onCreateOrGetHeaClicked,
    onCreateTafClicked,
    onUpdateDwellings
  }: ProjectDwellingsTableProps
) {

  const theme = useTheme();

  const tableRef = useRef();

  const [
    searchText,
    setSearchText
  ] = useState<string>();

  const [
    pageSize,
    setPageSize
  ] = useState<number>(5);

  const [
    pageNumber,
    setPageNumber
  ] = useState<number>(0);

  const [
    orderByField,
    setOrderByField
  ] = useState<string>("id");

  const [
    orderDirection,
    setOrderDirection
  ] = useState<string>("desc");

  const {
    dwellingsInProject,
    getDwellingsInProject,
    projectDwellingsLoading,
    totalCount
  } = useProjectDwellingsTable();

  useEffect(
    () => {
      getDwellingsInProject({
        id: projectId,
        page: pageNumber + 1,
        size: pageSize,
        search: searchText,
        orderByField,
        orderDirection
      });
    }, [
      getDwellingsInProject,
      orderByField,
      orderDirection,
      pageNumber,
      pageSize,
      projectId,
      searchText
    ]
  );

  const [
    resultsExistForSelectedDwelling,
    setResultsExistForSelectedDwelling
  ] = useState<boolean>(false);

  const [
    showAddDwellingsModal,
    setShowAddDwellingsModal
  ] = useState<boolean>(false);

  const [
    confirmationDialogOpen,
    setConfirmationDialogOpen
  ] = useState<boolean>(false);

  const [
    projectCostsDialogOpen,
    setEditProjectCostsDialogOpen
  ] = useState<boolean>(false);

  const [
    recommendedScenarioForDwelling,
    setRecommendedScenarioForDwelling
  ] = useState<ProjectScenario>();

  const [
    createHEATooltip,
    setCreateHEATooltip
  ] = useState<string>("No dwelling selected");

  const [
    createReportTooltip,
    setCreateReportTooltip
  ] = useState<string>("No dwelling selected");

  const [
    compareScenariosTooltip,
    setCompareScenariosTooltip
  ] = useState<string>("No dwelling selected");

  const [
    createTAFTooltip,
    setCreateTAFTooltip
  ] = useState<string>("No dwelling selected");

  const [
    editCostsTooltip,
    setEditCostsTooltip
  ] = useState<string>("No dwelling selected");

  useEffect(
    () => {
      if (isProjectRunning) {
        setCompareScenariosTooltip(
          "Can't compare scenarios while results are being generated"
        );
        setEditCostsTooltip(
          "Can't edit costs while results are being generated"
        );
        setCreateReportTooltip(
          "Can't create a report while results are being generated"
        );
        setCreateHEATooltip(
          "Can't create a Home Energy Assessment while results are being generated"
        );
        setCreateTAFTooltip(
          "Can't create a Technical Assessment Form while results are being generated"
        );
      } else {
        // eslint-disable-next-line no-lonely-if
        if (selectedDwellings.length === 0) {
          const noDwellingSelected = "No dwelling selected";
          setCompareScenariosTooltip(noDwellingSelected);
          setEditCostsTooltip(noDwellingSelected);
          setCreateReportTooltip(noDwellingSelected);
          setCreateHEATooltip(noDwellingSelected);
          setCreateTAFTooltip(noDwellingSelected);
        } else if (selectedDwellings.length === 1) {
          if (resultsExistForSelectedDwelling) {
            setCompareScenariosTooltip("Compare dwelling performance across scenarios");
            setEditCostsTooltip("Edit scenario measure costs and grants");
            setCreateReportTooltip("Create a report PDF");
            setCreateHEATooltip("Create a Home Energy Assessment");
            setCreateTAFTooltip("Create a Technical Assessment Form");
          } else {
            const resultsAreMissing = "Results are missing for your selection, " +
              "run the project to generate results";
            setCompareScenariosTooltip(resultsAreMissing);
            setEditCostsTooltip(resultsAreMissing);
            setCreateReportTooltip(resultsAreMissing);
            setCreateHEATooltip(resultsAreMissing);
            setCreateTAFTooltip(resultsAreMissing);
          }
        } else {
          const moreThanOne = "More than one dwelling selected";
          setCompareScenariosTooltip(moreThanOne);
          setEditCostsTooltip(moreThanOne);
          setCreateReportTooltip(moreThanOne);
          setCreateHEATooltip(moreThanOne);
          setCreateTAFTooltip(moreThanOne);
        }
      }
    },
    [
      isProjectRunning,
      resultsExistForSelectedDwelling,
      selectedDwellings.length
    ]
  );

  useEffect(
    () => {
      if (selectedDwellings.length === 1) {

        const scenarioResultsExistForSelectedDwelling = projectScenarios.every(
          (scenario) => {
            const relatedPerformanceIndex = scenario.related_performances.findIndex(
              (scenarioPerformance) =>
                scenarioPerformance.dwelling === selectedDwellings[0].id &&
                scenarioPerformance.dwelling_type === selectedDwellings[0].type
            );
            return relatedPerformanceIndex !== -1;
          }
        );

        setResultsExistForSelectedDwelling(scenarioResultsExistForSelectedDwelling);
      } else {
        setResultsExistForSelectedDwelling(false);
      }
    },
    [
      selectedDwellings,
      projectScenarios
    ]
  );

  const handleCloseAddDwellingsModal = () => {
    setShowAddDwellingsModal(false);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const handleCloseProjectCostsDialog = () => {
    setEditProjectCostsDialogOpen(false);
  };

  const handleOnAddDwellings = useCallback(
    (dwellings: Array<Dwelling | MinimalProjectDwelling>) => {
      onUpdateDwellings({
        add: dwellings.map((dwelling) => ({
          id: dwelling.id,
          type: dwelling.type
        })),
        remove: []
      });
    },
    [onUpdateDwellings]
  );

  const handleUpdateScenarioRecommendation = useCallback(
    (
      scenarioIdentifier: number,
      dwellingIdentifier: number,
      dwellingType: string
    ) => {
      handleUpdateProjectScenarioRecommendation({
        id: projectId,
        scenario_id: scenarioIdentifier,
        dwelling_id: dwellingIdentifier,
        dwelling_type: dwellingType
      });
    },
    [
      projectId,
      handleUpdateProjectScenarioRecommendation
    ]
  );

  const handleCreateOrGetHomeEnergyAssessment = useCallback(
    (dwelling: MinimalProjectDwelling) => {
      if (selectedDwellings.length === 1 && projectScenarios.length > 0) {
        const recommended = scenarioRecommendations.find(
          recommendation => recommendation.dwelling === dwelling.id
        );
        if (recommended !== undefined) {

          const recommendedScenario = projectScenarios.find(
            projectScenario => projectScenario.id === recommended.scenario
          );

          if (recommendedScenario !== undefined) {

            onCreateOrGetHeaClicked(
              {
                project_id: projectId,
                dwelling_id: dwelling.id,
                scenario_id: recommendedScenario.id,
                dwelling_type: dwelling.type
              },
              dwelling,
              recommendedScenario
            );
          } else {
            toast.error("No recommended scenario for this dwelling", {
              position: toast.POSITION.TOP_RIGHT,
              theme: "colored"
            });
          }
        } else {
          toast.error("No recommended scenario for this dwelling", {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored"
          });
        }
      }
    },
    [
      onCreateOrGetHeaClicked,
      projectId,
      projectScenarios,
      scenarioRecommendations,
      selectedDwellings
    ]
  );

  const handleCreateTechnicalAssessmentForm = useCallback(
    () => {
      if (selectedDwellings.length === 1 && projectScenarios.length > 0) {
        const dwelling = selectedDwellings[0];
        const recommended = scenarioRecommendations.find(
          recommendation => recommendation.dwelling === dwelling.id
        );
        if (recommended !== undefined) {
          onCreateTafClicked({
            project_id: projectId,
            dwelling_id: dwelling.id,
            scenario_id: recommended.scenario,
            type: dwelling.type
          });
        }
      }
    },
    [
      onCreateTafClicked,
      projectId,
      projectScenarios.length,
      scenarioRecommendations,
      selectedDwellings
    ]
  );

  const showScenarioCosts = useCallback(
    () => {
      const projectScenario = projectScenarios.find(
        (scenario) => {
          const recommended = scenarioRecommendations.find(
            recommendation => recommendation.dwelling === selectedDwellings[0].id
          );

          if (recommended !== undefined) {
            return scenario.id === recommended.scenario;
          }
          return false;
        }
      );

      if (projectScenario !== undefined) {
        setRecommendedScenarioForDwelling(projectScenario);
        setEditProjectCostsDialogOpen(true);
      } else {
        toast.info("No recommended scenario for this dwelling", {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored"
        });
      }
    },
    [
      projectScenarios,
      scenarioRecommendations,
      selectedDwellings
    ]
  );

  const tableColumns: Array<Column<MinimalProjectDwelling>> = useMemo(
    () => [
      ...DWELLING_TABLE_COLUMNS,
      {
        title: "Type",
        field: "type",
        align: "center",
        width: 50,
        cellStyle: {
          padding: "0 16px 0 16px"
        },
        render: (rowData) => {
          if (rowData.type === "individual") {
            return <Tooltip
              title="Individual heating scheme"
              arrow
              TransitionComponent={Zoom}
              placement="top"
            >
              <Avatar
                sx={{
                  backgroundColor: "#FFFFFF",
                  width: "36px",
                  height: "36px"
                }}
                aria-label="avatar-individual"
              >
                <PersonIcon style={{ color: theme.retrokitPalette.indigo.main }} />
              </Avatar>
            </Tooltip>;
          }
          return <Tooltip
            title="Group heating scheme"
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            <Avatar
              sx={{
                backgroundColor: theme.retrokitPalette.indigo.main,
                width: "36px",
                height: "36px"
              }}
              aria-label="avatar-group"
            >
              <GroupsIcon style={{ color: "#FFFFFF" }} />
            </Avatar>
          </Tooltip>;
        }
      },
      {
        title: "Rating",
        field: "rating",
        width: 50,
        cellStyle: {
          textAlign: "center",
          padding: "0 16px 0 16px"
        },
        render: (rowData) => <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <BERChevron
            rating={rowData.performance.rating}
          />
        </Box>
      },
      {
        title: "Scenario",
        field: "id",
        width: 220,
        cellStyle: {
          textAlign: "center",
          padding: "0 16px 0 16px"
        },
        render: (rowData) => {

          // NOTE: DO NOT REMOVE THE typeof CHECK FROM HERE OR BAD THINGS HAPPEN!
          // noinspection SuspiciousTypeOfGuard
          if (typeof scenarioRecommendations !== "string" && scenarioRecommendations.length > 0) {
            const recommendedScenario = scenarioRecommendations.find(
              recommendation => recommendation.dwelling === rowData.id &&
                recommendation.dwelling_type === rowData.type
            );

            return <Tooltip
              title="Recommended scenario"
              arrow
              TransitionComponent={Zoom}
              placement="top"
            >
              <Select
                labelId="recommended-scenario-select-label"
                id="recommended-scenario-select"
                value={
                  recommendedScenario?.scenario
                }
                label=""
                onChange={(event) => {
                  handleUpdateScenarioRecommendation(
                    parseInt(event.target.value as string, 10),
                    rowData.id,
                    rowData.type
                  );
                }}
                inputProps={{
                  sx: {
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    paddingLeft: "14px",
                    paddingRight: "14px",
                    display: "flex"
                  }
                }}
                variant="outlined"
                sx={{
                  width: "200px",
                  backgroundColor: "#FFFFFF"
                }}
              >
                {
                  projectScenarios.map((projectScenario) => {

                    const scenarioPerformance = projectScenario.related_performances.find(
                      performance => performance.dwelling === rowData.id
                    );

                    if (scenarioPerformance !== undefined) {
                      return <MenuItem
                        key={projectScenario.id}
                        value={projectScenario.id}
                        sx={{
                          display: "flex",
                          flexDirection: "row"
                        }}
                      >
                        <Typography
                          noWrap
                          variant="body1"
                          sx={{
                            display: "inline-block",
                            alignItems: "center",
                            flex: 1,
                            marginRight: "8px",
                            maxWidth: "82px"
                          }}
                        >
                          {projectScenario.title}
                        </Typography>

                        <BERChevron
                          rating={scenarioPerformance.rating}
                        />

                      </MenuItem>;
                    }

                    return <MenuItem
                      key={recommendedScenario?.scenario}
                      value={recommendedScenario?.scenario}
                    >

                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 14
                        }}
                      >
                        Run the project
                      </Typography>
                    </MenuItem>;
                  })
                }
              </Select>
            </Tooltip>;
          }
          return <Tooltip
            title="Recommended scenario"
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            <Select
              labelId="recommended-scenario-select-label"
              id="recommended-scenario-select"
              label=""
              onChange={(event) => {
                handleUpdateScenarioRecommendation(
                  parseInt(event.target.value as string, 10),
                  rowData.id,
                  rowData.type
                );
              }}
              variant="outlined"
              sx={{
                width: "200px",
                backgroundColor: "#FFFFFF"
              }}
            >
              <MenuItem
                key={0}
                value={0}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 14
                  }}
                >
                  Run the project
                </Typography>
              </MenuItem>
            </Select>
          </Tooltip>;
        }
      }
    ],
    [
      handleUpdateScenarioRecommendation,
      projectScenarios,
      scenarioRecommendations,
      theme.retrokitPalette.indigo.main
    ]
  );

  const TablePaperComponent = useCallback(
    (props: JSX.IntrinsicAttributes & PaperProps) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      {...props}
    />,
    []
  );

  const HideTableToolbarComponent = useCallback(
    (props: JSX.IntrinsicAttributes & PaperProps) => <Box
      sx={{
        display: "none",
        height: "0px"
      }}
    >
      <MTableToolbar
        {...props}
      />
    </Box>, []
  );

  return <Box
    sx={{
      height: "100%",
      width: "100%",
      padding: 0,
      margin: 0
    }}
  >
    <Box
      sx={{
        borderBottom: "1px solid #c0c0c0"
      }}
    >
      <ButtonGroup
        variant="text"
        sx={{
          width: "100%",
          display: "flex"
        }}
      >
        <Tooltip
          title={compareScenariosTooltip}
          placement="top"
          sx={{
            flex: 1
          }}
        >
          <Box
            sx={{
              width: "100%"
            }}
          >
            <Button
              sx={{
                padding: "16px 18px",
                width: "100%"
              }}
              color="primary"
              endIcon={
                isProjectRunning || !resultsExistForSelectedDwelling ?
                  <CompareIcon /> : <CompareIcon
                    color="secondary"
                  />
              }
              disabled={isProjectRunning || !resultsExistForSelectedDwelling}
              onClick={() => {
                setShowCompareScenariosModal(true);
                onClickScenarioComparison(
                  selectedDwellings[0]
                );
              }}
            >
              Compare Scenarios
            </Button>
          </Box>
        </Tooltip>

        <Tooltip
          title={editCostsTooltip}
          placement="top"
          sx={{
            flex: 1
          }}
        >
          <Box
            sx={{
              width: "100%"
            }}
          >
            <Button
              sx={{
                padding: "16px 18px",
                width: "100%"
              }}
              color="primary"
              endIcon={
                isProjectRunning || !resultsExistForSelectedDwelling ?
                  <EuroIcon /> : <EuroIcon
                    color="secondary"
                  />
              }
              disabled={isProjectRunning || !resultsExistForSelectedDwelling}
              onClick={() => {
                showScenarioCosts();
              }}
            >
              Edit Costs
            </Button>
          </Box>
        </Tooltip>

        <Tooltip
          title={createReportTooltip}
          placement="top"
          sx={{
            flex: 1
          }}
        >
          <Box
            sx={{
              width: "100%"
            }}
          >
            <Button
              sx={{
                padding: "16px 18px",
                width: "100%"
              }}
              color="primary"
              endIcon={
                isProjectRunning || !resultsExistForSelectedDwelling ?
                  <PdfIcon /> : <PdfIcon
                    color="secondary"
                  />
              }
              disabled={isProjectRunning || !resultsExistForSelectedDwelling}
              onClick={() => {
                onClickGeneratePDFReport(
                  selectedDwellings[0]
                );
              }}
            >
              Create report
            </Button>
          </Box>
        </Tooltip>

        <Tooltip
          title={createHEATooltip}
          placement="top"
          sx={{
            flex: 1
          }}
        >
          <Box
            sx={{
              width: "100%"
            }}
          >
            <Button
              sx={{
                padding: "16px 18px",
                width: "100%"
              }}
              color="primary"
              endIcon={
                isProjectRunning || !resultsExistForSelectedDwelling ?
                  <AssessmentIcon /> : <AssessmentIcon
                    color="secondary"
                  />
              }
              disabled={isProjectRunning || !resultsExistForSelectedDwelling}
              onClick={() => {
                handleCreateOrGetHomeEnergyAssessment(
                  selectedDwellings[0]
                );
              }}
            >
              Create HEA
            </Button>
          </Box>
        </Tooltip>

        <Tooltip
          title={createTAFTooltip}
          placement="top"
          sx={{
            flex: 1
          }}
        >
          <Box
            sx={{
              width: "100%"
            }}
          >
            <Button
              sx={{
                padding: "16px 18px",
                width: "100%"
              }}
              color="primary"
              endIcon={
                isProjectRunning || !resultsExistForSelectedDwelling ?
                  <TableViewIcon /> : <TableViewIcon
                    color="secondary"
                  />
              }
              disabled={isProjectRunning || !resultsExistForSelectedDwelling}
              onClick={() => {
                handleCreateTechnicalAssessmentForm();
              }}
            >
              Create TAF
            </Button>
          </Box>
        </Tooltip>

      </ButtonGroup>

    </Box>

    <Box
      sx={{
        backgroundColor: selectedDwellings.length > 0 ? "#FCE4EC" : "#FFFFFF"
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: selectedDwellings.length > 0 ? theme.palette.secondary.main : theme.palette.text.primary,
              marginBottom: 0
            }}
          >
            {
              selectedDwellings.length > 0 ?
                `${selectedDwellings.length} ${selectedDwellings.length === 1 ? "dwelling" : "dwellings"} selected` :
                `Dwellings (${totalCount})`
            }
          </Typography>

          {
            selectedDwellings.length > 0 ? <Tooltip
              title="Deselect"
              placement="top"
            >
              <IconButton
                type="button"
                sx={{
                  p: "10px"
                }}
              >
                <CloseIcon
                  color="secondary"
                  onClick={
                    () => {
                      if (tableRef.current) {
                        // @ts-expect-error tableRef.current type is unknown
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        tableRef.current.onAllSelected(false);
                        setSelectedDwellings([]);
                      }
                    }
                  }
                />
              </IconButton>
            </Tooltip> : null
          }

        </Box>

        <Box
          sx={{
            flex: 1
          }}
        />

        <Paper
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center"
          }}
          elevation={0}
        >
          <IconButton
            sx={{
              p: "10px"
            }}
          >
            <SearchIcon />
          </IconButton>

          <InputBase
            placeholder="Search"
            value={searchText}
            onChange={
              (event) => {
                setSearchText(event.target.value);
                setPageNumber(0);
              }
            }
          />

          <IconButton
            type="button"
            sx={{
              p: "10px"
            }}
          >
            <CloseIcon
              onClick={
                () => {
                  setSearchText("");
                  setPageNumber(0);
                }
              }
            />
          </IconButton>

        </Paper>

        <Tooltip
          title={
            // eslint-disable-next-line no-nested-ternary
            selectedDwellings.length > 0 ?
              `Remove ${
                selectedDwellings.length === 1 ? "dwelling" : "dwellings"
              } from project` : "Add dwellings to the project"
          }
          placement="top"
        >
          <IconButton
            type="button"
            sx={{
              p: "10px"
            }}
            disabled={isProjectRunning}
          >
            {
              // eslint-disable-next-line no-nested-ternary
              selectedDwellings.length > 0 ?
                isProjectRunning ?
                  <DeleteIcon /> : <DeleteIcon
                    color="secondary"
                    onClick={
                      () => {
                        setConfirmationDialogOpen(true);
                      }
                    }
                  /> :
                isProjectRunning ?
                  <AddIcon /> : <AddIcon
                    sx={{
                      color: theme.retrokitPalette.green.dark
                    }}
                    onClick={() => {
                      setShowAddDwellingsModal(true);
                    }}
                  />
            }
          </IconButton>
        </Tooltip>

      </Toolbar>

    </Box>

    <MaterialTable
      tableRef={tableRef}
      columns={tableColumns}
      components={{
        Container: TablePaperComponent,
        Toolbar: HideTableToolbarComponent
      }}
      data={
        // NOTE: This map function is required to preserve selections
        // after performing searches
        dwellingsInProject.map(
          (dwelling) => {
            if (
              selectedDwellings.find(
                selectedDwelling => selectedDwelling.id === dwelling.id
              )
            ) {
              return {
                ...dwelling,
                tableData: {
                  checked: true
                }
              };
            }
            return dwelling;
          }
        )
      }
      isLoading={projectDwellingsLoading}
      onOrderCollectionChange={
        (newOrderByCollection) => {
          if (newOrderByCollection.length === 1) {
            setOrderByField(newOrderByCollection[0].orderByField);
            setOrderDirection(newOrderByCollection[0].orderDirection);
          }
        }
      }
      localization={{
        body: {
          emptyDataSourceMessage: "No dwellings in project",
          filterRow: {
            filterTooltip: "Filter"
          }
        }
      }}
      options={{
        actionsColumnIndex: -1,
        // fixedColumns: {
        //   //left: 2
        //   right: 1
        // },
        headerSelectionProps: {
          style: {
            padding: "0px"
          }
        },
        headerStyle: {
          backgroundColor: colors.blue["500"],
          color: theme.palette.common.white,
          padding: "0 16px 0 16px",
          fontWeight: "bold"
        },
        padding: "dense",
        paging: false,
        rowStyle: (rowData, index) => {
          if (
            // @ts-expect-error tableData type is not known to TypeScript
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            rowData.tableData.checked === true
          ) {
            return {
              backgroundColor: "#fce4ec",
              color: theme.palette.secondary.main,
              fontWeight: "bold",
              fontSize: 14
            };
          }
          if (index % 2) {
            return {
              backgroundColor: colors.indigo["100"],
              fontSize: 14
            };
          }
          return {
            backgroundColor: colors.indigo["50"],
            fontSize: 14
          };
        },
        search: false,
        selection: true,
        selectionProps: {
          style: {
            padding: "0 16px 0 16px"
          }
        },
        showTitle: false,
        tableLayout: "fixed"
      }}
      onSelectionChange={
        (rows) => {
          const selectedDwellingsNotCurrentlyShowing = selectedDwellings.filter(
            selectedDwelling => dwellingsInProject.find(
              dwelling => dwelling.id === selectedDwelling.id
            ) === undefined
          );
          setSelectedDwellings(
            [...selectedDwellingsNotCurrentlyShowing, ...rows]
          );
        }
      }
    />

    <Box>
      <TablePagination
        component="div"
        count={totalCount}
        page={pageNumber}
        onPageChange={(event, newPageNumber) => {
          setPageNumber(newPageNumber);
        }}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 20, 50]}
        onRowsPerPageChange={(event) => {
          setPageSize(Number(event.target.value));
        }}
      />
    </Box>

    <AddDwellingsToProjectDialog
      projectId={projectId}
      projectTitle={projectTitle}
      open={showAddDwellingsModal}
      onClose={handleCloseAddDwellingsModal}
      onAddDwellings={handleOnAddDwellings}
    />

    {
      selectedDwellings.length === 1 &&
      recommendedScenarioForDwelling !== undefined &&
      projectCostsDialogOpen ?
        <ProjectCostsDialog
          onClose={handleCloseProjectCostsDialog}
          open={projectCostsDialogOpen}
          projectId={projectId}
          dwelling={selectedDwellings[0]}
          scenario={recommendedScenarioForDwelling}
          reportConfig={projectReportConfig}
        /> : null
    }


    <ConfirmationDialog
      message={
        selectedDwellings.length > 1 ?
          "This will delete all results for the selected dwellings" :
          "This will delete all results for this dwelling"
      }
      title={
        selectedDwellings.length > 1 ?
          "Confirm remove dwellings" :
          "Confirm remove dwelling"
      }
      onClose={handleCloseConfirmationDialog}
      onSubmit={
        () => {
          onRemoveDwellings(selectedDwellings);
          setSelectedDwellings([]);
          handleCloseConfirmationDialog();
        }
      }
      open={confirmationDialogOpen}
    />
  </Box>;
}
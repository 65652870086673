import { useEffect, useState } from "react";

import { 
  useLazyGetDwellingsNotInProjectQuery 
} from "features/projects/api/projectsApi";

import type { Dwelling } from "features/dwellings/types";
import type { MinimalProjectDwelling } from "features/projects/types";



export function useAddDwellingsToProjectDialog() {

  const [
    totalCount,
    setTotalCount
  ] = useState<number>(0);

  const [
    dwellingsNotInProjectIsLoading,
    setDwellingsNotInProjectIsLoading
  ] = useState(false);

  const [
    dwellingsNotInProject,
    setDwellingsNotInProject
  ] = useState<Array<Dwelling | MinimalProjectDwelling>>([]);

  const [
    getDwellingsNotInProject,
    {
      data: paginatedDwellingsNotInProject,
      isFetching: isGetDwellingNotInProjectFetching,
      isLoading: isGetDwellingNotInProjectLoading
    }
  ] = useLazyGetDwellingsNotInProjectQuery();

  useEffect(
    () => {
      setDwellingsNotInProjectIsLoading(
        isGetDwellingNotInProjectLoading ||
        isGetDwellingNotInProjectFetching
      );
    },
    [
      isGetDwellingNotInProjectLoading,
      isGetDwellingNotInProjectFetching
    ]
  );

  useEffect(
    () => {
      setDwellingsNotInProject(
        paginatedDwellingsNotInProject?.results ?? []
      );
      setTotalCount(
        paginatedDwellingsNotInProject?.count ?? 0
      );
    }, [
      paginatedDwellingsNotInProject
    ]);

  return {
    getDwellingsNotInProject,
    dwellingsNotInProjectIsLoading,
    dwellingsNotInProject,
    totalCount
  };
}

import React, {
    type ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";

import MaterialTable, {type Column} from "@material-table/core";

import {
    colors
} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import type {PaperProps} from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {BEREdited} from "common/components/BEREdited";
import {
    DwellingAndScenarioPerformanceBanner
} from "common/components/DwellingAndScenarioPerformanceBanner";
import {
    HEA_COST_ESTIMATES_BOTTOM_TABLE_COLUMNS,
    HEA_COST_ESTIMATES_TABLE_COLUMNS
} from "common/constants/tables";
import iconTitleHomeBlank from "common/icons/icon-title-home-blank.png";
import {
    useHomeEnergyAssessment
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";
import {
    useProjectReportConfig
} from "features/projectsReportConfig/hooks/useProjectReportConfig";

import type {Dwelling} from "features/dwellings/types";
import type {
    HomeEnergyAssessmentCostEstimatesTableData,
    HomeEnergyAssessmentCostEstimatesBottomTableData
} from "features/homeEnergyAssessment/types";
import type {
    MinimalProjectDwelling,
    ProjectReportConfig
} from "features/projects/types";


interface HomeEnergyAssessmentDialogContent11Props {
    contentBackgroundColour: string;
    pageBackgroundColour: string;
    dwelling: Dwelling | MinimalProjectDwelling;
    projectId: number;
    reportConfig: ProjectReportConfig;
    scenarioDetail: string;
    scenarioRating: string;
    inputError: boolean;
    setInputError: React.Dispatch<React.SetStateAction<boolean>>;
    handleHeaFieldBlur: (
        value: string | number | File,
        field: string
    ) => void;
}


export function DialogContentCosts(
    {
        contentBackgroundColour,
        pageBackgroundColour,
        dwelling,
        projectId,
        reportConfig,
        scenarioDetail,
        scenarioRating,
        inputError,
        setInputError,
        handleHeaFieldBlur
    }: HomeEnergyAssessmentDialogContent11Props
) {

    const {
        homeEnergyAssessment
    } = useHomeEnergyAssessment();

    const {
        updateProjectReportConfig
    } = useProjectReportConfig(projectId);

    const [
        sectionHeadingCostEstimates,
        setSectionHeadingCostEstimates
    ] = useState<string>("");

    const [
        sectionHeadingCostEstimatesError,
        setSectionHeadingCostEstimatesError
    ] = useState<boolean>(false);

    const [
        sectionHeadingCostEstimatesLength,
        setSectionHeadingCostEstimatesLength
    ] = useState<number>(0);

    const sectionHeadingCostEstimatesLengthLimit = 100;

    const sectionHeadingCostEstimatesInputRef = useRef<HTMLInputElement>();

    const [
        scheduleOfPaymentsParagraph,
        setScheduleOfPaymentsParagraph
    ] = useState<string>("");

    const [
        scheduleOfPaymentsParagraphError,
        setScheduleOfPaymentsParagraphError
    ] = useState<boolean>(false);

    const [
        scheduleOfPaymentsParagraphLength,
        setScheduleOfPaymentsParagraphLength
    ] = useState<number>(0);

    const scheduleOfPaymentsParagraphLengthLimit = 500;

    const scheduleOfPaymentsParagraphInputRef = useRef<HTMLInputElement>();

    const [
        sectionHeadingScheduleOfPayments,
        setSectionHeadingScheduleOfPayments
    ] = useState<string>("");

    const [
        financingOptionParagraph,
        setFinancingOptionParagraph
    ] = useState<string>("");

    const [
        financingOptionParagraphError,
        setFinancingOptionParagraphError
    ] = useState<boolean>(false);

    const [
        financingOptionParagraphLength,
        setFinancingOptionParagraphLength
    ] = useState<number>(0);

    const financingOptionParagraphLengthLimit = 500;

    const [
        sectionHeadingFinancingOptionParagraph,
        setSectionHeadingFinancingOptionParagraph
    ] = useState<string>("");

    const [
        costEstimatesTableData,
        setCostEstimatesTableData
    ] = useState<HomeEnergyAssessmentCostEstimatesTableData[]>([]);

    const [
        costEstimatesBottomTableData,
        setCostEstimatesBottomTableData
    ] = useState<HomeEnergyAssessmentCostEstimatesBottomTableData[]>([]);

    const filteredColumns =
        HEA_COST_ESTIMATES_TABLE_COLUMNS.filter(
            (tableColumn) => {

                if (!reportConfig.show_costs) {
                    return tableColumn.field !== "cost";
                }

                if (!reportConfig.show_grants) {
                    return tableColumn.field !== "grant";
                }

                return true;
            }
        );

    const filteredColumnsBottomTable =
        HEA_COST_ESTIMATES_BOTTOM_TABLE_COLUMNS.filter(
            (tableColumn) => {

                if (!reportConfig.show_costs) {
                    return tableColumn.field !== "cost";
                }

                if (!reportConfig.show_grants) {
                    return tableColumn.field !== "grant";
                }

                return true;
            }
        );

    const [
        tableData,
        setTableData
    ] = useState<HomeEnergyAssessmentCostEstimatesTableData[]>([]);

    const roundToNearestHundred = (someNumber: number) => Math.round(
        someNumber / 100
    ) * 100;

    useEffect(
        () => {

            if (
                scheduleOfPaymentsParagraphError ||
                financingOptionParagraphError ||
                sectionHeadingCostEstimatesError
            ) {
                setInputError(true);
            }

            if (inputError) {
                if (
                    !scheduleOfPaymentsParagraphError &&
                    !financingOptionParagraphError &&
                    !sectionHeadingCostEstimatesError
                ) {
                    setInputError(false);
                }
            }

            if (reportConfig !== undefined) {
                if (reportConfig.round_costs) {
                    const tableDataCopy = [
                        ...costEstimatesTableData
                    ];

                    // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < tableDataCopy.length; i++) {
                        const newRowData = {
                            ...tableDataCopy[i]
                        };

                        newRowData.cost = roundToNearestHundred(
                          tableDataCopy[i].cost
                        );

                        tableDataCopy[i] = newRowData;
                    }
                    setTableData(tableDataCopy);
                } else {
                    setTableData(costEstimatesTableData);
                }
            }

        },
        [
            scheduleOfPaymentsParagraphError,
            financingOptionParagraphError,
            sectionHeadingCostEstimatesError,
            inputError,
            setInputError,
            reportConfig,
            costEstimatesTableData
        ]
    );

    useEffect(
        () => {
            if (homeEnergyAssessment !== undefined) {
                setSectionHeadingCostEstimates(
                    homeEnergyAssessment.section_heading_cost_estimates
                );

                setSectionHeadingCostEstimatesLength(
                    homeEnergyAssessment.section_heading_cost_estimates !== null ?
                        homeEnergyAssessment.section_heading_cost_estimates.length : 0
                );

                setScheduleOfPaymentsParagraph(
                    homeEnergyAssessment.schedule_of_payments_paragraph
                );

                setScheduleOfPaymentsParagraphLength(
                    homeEnergyAssessment.schedule_of_payments_paragraph !== null ?
                        homeEnergyAssessment.schedule_of_payments_paragraph.length : 0
                );

                setFinancingOptionParagraph(
                    homeEnergyAssessment.financing_option_paragraph
                );

                setFinancingOptionParagraphLength(
                    homeEnergyAssessment.financing_option_paragraph !== null ?
                        homeEnergyAssessment.financing_option_paragraph.length : 0
                );

                setCostEstimatesTableData(
                    homeEnergyAssessment.cost_estimates_table_data
                );

                setSectionHeadingScheduleOfPayments(
                    homeEnergyAssessment.section_heading_schedule_of_payments
                );

                setSectionHeadingFinancingOptionParagraph(
                    homeEnergyAssessment.section_heading_financing_option
                );

                const tableDataLower: any[] = [];

                let berAndHeatPumpBonus =
                    homeEnergyAssessment.ber_and_heat_pump_bonus;

                let centralHeatingAndHeatPumpBonus =
                    homeEnergyAssessment.central_heating_and_heat_pump_bonus;

                let energyCredits = homeEnergyAssessment.credit_total;

                let totalCosts;
                let totalGrants;
                if (reportConfig.round_costs) {
                    totalCosts = homeEnergyAssessment.cost_estimates_table_data.reduce(
                        (partialSum, currentObject) =>
                            partialSum + roundToNearestHundred(currentObject.cost), 0
                    );

                    totalGrants = homeEnergyAssessment.cost_estimates_table_data.reduce(
                      (partialSum, currentObject) =>
                        partialSum + roundToNearestHundred(currentObject.grant), 0
                    );

                    berAndHeatPumpBonus = roundToNearestHundred(
                      berAndHeatPumpBonus
                    );

                    centralHeatingAndHeatPumpBonus = roundToNearestHundred(
                      centralHeatingAndHeatPumpBonus
                    );

                    energyCredits = roundToNearestHundred(
                      energyCredits
                    );

                } else {
                    totalCosts = homeEnergyAssessment.cost_estimates_table_data.reduce(
                        (partialSum, currentObject) =>
                            partialSum + currentObject.cost, 0
                    );

                    totalGrants = homeEnergyAssessment.cost_estimates_table_data.reduce(
                      (partialSum, currentObject) =>
                        partialSum + currentObject.grant, 0
                    );
                }

                totalGrants += berAndHeatPumpBonus +
                  centralHeatingAndHeatPumpBonus

                let totalCostToHomeowner = totalCosts;

                if (reportConfig.show_costs) {
                    tableDataLower.push({
                        id: 0,
                        grant: 0,
                        cost: totalCosts,
                        title: "Total cost",
                    });
                }

                if (reportConfig.show_grants) {

                    totalCostToHomeowner -= totalGrants;

                    tableDataLower.push({
                        id: 1,
                        grant: berAndHeatPumpBonus.toFixed(0),
                        cost: 0,
                        title: "BER and heat pump bonus",
                        variableName: "berAndHeatPumpBonus"
                    });
                    tableDataLower.push({
                        id: 2,
                        grant: centralHeatingAndHeatPumpBonus.toFixed(0),
                        cost: 0,
                        title: "Central heating and heat pump bonus",
                        variableName: "centralHeatingAndHeatPumpBonus"
                    });
                }

                if (reportConfig.show_credits) {

                    totalCostToHomeowner -= energyCredits;

                    tableDataLower.push({
                        id: 3,
                        grant: energyCredits.toFixed(0),
                        cost: 0,
                        title: "Total credits",
                        variableName: "totalCredits"
                    });
                }

                if (reportConfig.show_grants) {
                    tableDataLower.push({
                        id: 4,
                        grant: totalGrants,
                        cost: 0,
                        title: "Total grants",
                        variableName: "totalGrants"
                    });
                }

                if (reportConfig.show_costs) {
                    tableDataLower.push({
                        id: 5,
                        grant: 0,
                        cost: totalCostToHomeowner,
                        title: "Total cost to the homeowner",
                    });
                }

                setCostEstimatesBottomTableData(tableDataLower);

            }
        },
        [
            homeEnergyAssessment, reportConfig
        ]
    );

    const handleUpdateRoundCosts = useCallback(
      (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
          updateProjectReportConfig({
              project_id: projectId,
              round_costs: checked
          });
      },
      [
          projectId,
          updateProjectReportConfig
      ]
    );

    const handleUpdateShowCredits = useCallback(
      (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
          updateProjectReportConfig({
              project_id: projectId,
              show_credits: checked
          });
      },
      [projectId, updateProjectReportConfig]
    );

    const handleUpdateShowCosts = useCallback(
      (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
          updateProjectReportConfig({
              project_id: projectId,
              show_costs: checked
          });
      },
      [projectId, updateProjectReportConfig]
    );

    const handleUpdateShowGrants = useCallback(
      (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
          updateProjectReportConfig({
              project_id: projectId,
              show_grants: checked
          });
      },
      [projectId, updateProjectReportConfig]
    );
    
    const handleFieldUpdated = useCallback(
        (
            value: string | number | File,
            field: string
        ) => {
            handleHeaFieldBlur(value, field);
        },
        [
            handleHeaFieldBlur
        ]
    );

    const tableColumns: Array<Column<HomeEnergyAssessmentCostEstimatesTableData>> = useMemo(
        () => [
            ...filteredColumns
        ],
        [
            filteredColumns
        ]
    );

    const bottomTableColumns: Array<Column<HomeEnergyAssessmentCostEstimatesBottomTableData>> = useMemo(
        () => [
            ...filteredColumnsBottomTable
        ],
        [
            filteredColumnsBottomTable
        ]
    );

    const TablePaperComponent = useCallback(
        (
            props: JSX.IntrinsicAttributes & PaperProps
        ) => <Paper
            sx={{
                borderRadius: "20px 20px 0 0",
                backgroundColor: colors.grey["200"]
            }}
            variant="outlined"
            {...props}
        />,
        []
    );

    const BottomTablePaperComponent = useCallback(
        (
            props: JSX.IntrinsicAttributes & PaperProps
        ) => <Paper
            sx={{
                "& tbody tr td": {
                    border: 0,
                    backgroundColor: colors.grey["100"]
                },
                border: 0
            }}
            variant="outlined"
            elevation={0}
            {...props}
        />,
        []
    );

    const theme = useTheme();

    return (
        <DialogContent
            sx={{
                width: "100%",
                flex: 1,
                overflowX: "hidden",
                overflowY: "hidden",
                padding: "0px 8px 0px 8px",
                gap: "2rem"
            }}
        >

            <Box
                sx={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingBottom: "16px"
                }}
            >

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        borderRadius: "20px",
                        backgroundColor: contentBackgroundColour,
                        padding: "16px"
                    }}
                >
                    <img
                        src={iconTitleHomeBlank}
                        alt="Icon"
                        style={{
                            maxHeight: "48px"
                        }}
                    />

                    <TextField
                        error={sectionHeadingCostEstimatesError}
                        variant="standard"
                        fullWidth
                        inputProps={{
                            style: {
                                fontSize: "1.4rem",
                                backgroundColor: theme.palette.common.white
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: "1.4rem"
                            }
                        }}
                        FormHelperTextProps={{
                            sx: {
                                textAlign: "right",
                                width: "100%"
                            }
                        }}
                        value={sectionHeadingCostEstimates}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "16px",
                            color: colors.grey["800"]
                        }}
                        onBlur={(event) => {
                            if (!inputError) {
                                handleFieldUpdated(
                                    event.target.value,
                                    "section_heading_cost_estimates"
                                );
                            }
                        }}
                        onChange={(event) => {
                            const {
                                value
                            } = event.target;
                            setSectionHeadingCostEstimatesLength(value.length);
                            if (value.length > sectionHeadingCostEstimatesLengthLimit) {
                                setSectionHeadingCostEstimatesError(true);
                            } else if (sectionHeadingCostEstimatesError) {
                                setSectionHeadingCostEstimatesError(false);
                            }
                            setSectionHeadingCostEstimates(value);
                        }}
                        helperText={
                            sectionHeadingCostEstimatesLengthLimit - sectionHeadingCostEstimatesLength <= (sectionHeadingCostEstimatesLengthLimit / 2) ?
                                `${sectionHeadingCostEstimatesLength}/${sectionHeadingCostEstimatesLengthLimit}` : ""
                        }
                        onKeyUp={(event) => {
                            if (event.key === "Enter") {
                                if (!inputError) {
                                    if (
                                        scheduleOfPaymentsParagraphInputRef !== undefined &&
                                        scheduleOfPaymentsParagraphInputRef.current !== undefined
                                    ) {
                                        scheduleOfPaymentsParagraphInputRef.current.focus();
                                    }
                                }
                            }
                        }}
                        inputRef={sectionHeadingCostEstimatesInputRef}
                    />

                </Box>

            </Box>

            <Box
                sx={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingBottom: "16px"
                }}
            >
                <DwellingAndScenarioPerformanceBanner
                    dwellingDetail={String(dwelling.mprn)}
                    dwellingRating={dwelling.performance.rating}
                    scenarioDetail={scenarioDetail}
                    scenarioRating={scenarioRating}
                />

            </Box>

            {
                dwelling.performance.ber_edited ? <BEREdited/> : null
            }

            <Box
              sx={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingBottom: "16px"
              }}
            >
                <Box
                  sx={{
                      border: "1px",
                      borderRadius: "20px",
                      backgroundColor: theme.palette.common.white,
                      padding: "16px"
                  }}
                >
                    <Typography
                      variant="h6"
                      style={{
                          fontSize: "18px",
                          fontWeight: 400,
                          color: theme.palette.secondary.main
                      }}
                    >
                        Costs
                    </Typography>

                    <Box
                      sx={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "8px",
                          gap: "8px",
                          justifyContent: "center"
                      }}
                    >

                        <FormControlLabel
                          control={
                              <Checkbox
                                checked={reportConfig.round_costs}
                                onChange={handleUpdateRoundCosts}
                              />
                          }
                          label="Round to Nearest Hundred"
                        />

                        <FormControlLabel
                          control={
                              <Checkbox
                                checked={reportConfig.show_costs}
                                onChange={handleUpdateShowCosts}
                              />
                          }
                          label="Show Costs"
                        />

                        <FormControlLabel
                          control={
                              <Checkbox
                                checked={reportConfig.show_credits}
                                onChange={handleUpdateShowCredits}
                              />
                          }
                          label="Show Credits"
                        />

                        <FormControlLabel
                          control={
                              <Checkbox
                                checked={reportConfig.show_grants}
                                onChange={handleUpdateShowGrants}
                              />
                          }
                          label="Show Grants"
                        />

                    </Box>

                </Box>
            </Box>



            <Box
                sx={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingBottom: "16px"
                }}
            >

                <MaterialTable
                    columns={tableColumns}
                    components={{
                        Container: TablePaperComponent,
                        Toolbar: () => null
                    }}
                    data={tableData}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "No measures to display",
                            filterRow: {
                                filterTooltip: "Filter"
                            }
                        }
                    }}
                    options={{
                        idSynonym: "step",
                        headerStyle: {
                            color: theme.palette.common.black,
                            padding: "6px 12px 6px 12px",
                            height: "56px",
                            fontWeight: 500,
                            fontSize: "0.875rem"
                        },
                        padding: "dense",
                        paging: false,
                        rowStyle: () => ({
                            fontWeight: 400,
                            fontSize: "0.875rem",
                            backgroundColor: theme.palette.common.white
                        }),
                        search: false,
                        sorting: false,
                        showTitle: false,
                        tableLayout: "fixed",
                        thirdSortClick: false
                    }}
                />

                <MaterialTable
                    columns={bottomTableColumns}
                    components={{
                        Container: BottomTablePaperComponent,
                        Header: () => null,
                        Toolbar: () => null
                    }}
                    data={costEstimatesBottomTableData}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "No costs to display",
                            filterRow: {
                                filterTooltip: "Filter"
                            }
                        }
                    }}
                    options={{
                        padding: "dense",
                        paging: false,
                        rowStyle: () => ({
                            fontWeight: "bold",
                            fontSize: "0.875rem",
                            backgroundColor: theme.palette.common.white,
                            border: 0
                        }),
                        search: false,
                        sorting: false,
                        showTitle: false,
                        tableLayout: "fixed",
                        thirdSortClick: false
                    }}
                />

            </Box>

            <Box
                sx={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingBottom: "16px"
                }}
            >
                <TextField
                    error={scheduleOfPaymentsParagraphError}
                    label={sectionHeadingScheduleOfPayments}
                    sx={{
                        backgroundColor: pageBackgroundColour,
                        width: "100%"
                    }}
                    multiline
                    size="small"
                    variant="outlined"
                    InputProps={{
                        sx: {
                            backgroundColor: theme.palette.common.white
                        }
                    }}
                    FormHelperTextProps={{
                        sx: {
                            textAlign: "right"
                        }
                    }}
                    value={scheduleOfPaymentsParagraph}
                    onBlur={(event) => {
                        if (!inputError) {
                            handleFieldUpdated(
                                event.target.value,
                                "schedule_of_payments_paragraph"
                            );
                        }
                    }}
                    onChange={(event) => {
                        const {
                            value
                        } = event.target;
                        setScheduleOfPaymentsParagraphLength(value.length);
                        if (value.length > scheduleOfPaymentsParagraphLengthLimit) {
                            setScheduleOfPaymentsParagraphError(true);
                        } else if (scheduleOfPaymentsParagraphError) {
                            setScheduleOfPaymentsParagraphError(false);
                        }
                        setScheduleOfPaymentsParagraph(value);
                    }}
                    helperText={
                        scheduleOfPaymentsParagraphLengthLimit - scheduleOfPaymentsParagraphLength <= (scheduleOfPaymentsParagraphLengthLimit / 2) ?
                            `${scheduleOfPaymentsParagraphLength}/${scheduleOfPaymentsParagraphLengthLimit}` : ""
                    }
                    inputRef={scheduleOfPaymentsParagraphInputRef}
                />

            </Box>

            <Box
                sx={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingBottom: "16px"
                }}
            >
                <TextField
                    error={financingOptionParagraphError}
                    label={sectionHeadingFinancingOptionParagraph}
                    sx={{
                        backgroundColor: pageBackgroundColour,
                        width: "100%"
                    }}
                    multiline
                    size="small"
                    variant="outlined"
                    InputProps={{
                        sx: {
                            backgroundColor: theme.palette.common.white
                        }
                    }}
                    FormHelperTextProps={{
                        sx: {
                            textAlign: "right"
                        }
                    }}
                    value={financingOptionParagraph}
                    onBlur={(event) => {
                        if (!inputError) {
                            handleFieldUpdated(
                                event.target.value,
                                "financing_option_paragraph"
                            );
                        }
                    }}
                    onChange={(event) => {
                        const {
                            value
                        } = event.target;
                        setFinancingOptionParagraphLength(value.length);
                        if (value.length > financingOptionParagraphLengthLimit) {
                            setFinancingOptionParagraphError(true);
                        } else if (financingOptionParagraphError) {
                            setFinancingOptionParagraphError(false);
                        }
                        setFinancingOptionParagraph(value);
                    }}
                    helperText={
                        financingOptionParagraphLengthLimit - financingOptionParagraphLength <= (financingOptionParagraphLengthLimit / 2) ?
                            `${financingOptionParagraphLength}/${financingOptionParagraphLengthLimit}` : ""
                    }
                />

            </Box>

        </DialogContent>
    );
}
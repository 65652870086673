import React, { useCallback } from "react";

import MaterialTable from "@material-table/core";

import { PictureAsPdf as PdfIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Paper, { type PaperProps } from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { DwellingMapImage } from "common/components/DwellingMapImage";
import { RetroKitIcon } from "common/components/RetroKitIcon";

import type { Column } from "@material-table/core";
import type { Dwelling } from "features/dwellings/types";
import type { MinimalProjectDwelling, ProjectThresholds } from "features/projects/types";
import type { ProjectScenario } from "features/projectScenarios/types";


interface DwellingScenarioComparisonDialogProps {
  dwelling: Dwelling | MinimalProjectDwelling;
  isLoading: boolean;
  onClose: () => void;
  onGeneratePdf: (dwelling: Dwelling | MinimalProjectDwelling) => void;
  open: boolean;
  projectTitle: string;
  projectScenarios: ReadonlyArray<ProjectScenario>;
  thresholds: ProjectThresholds;
}

export function DwellingScenarioComparisonDialog(
  {
    dwelling,
    isLoading,
    onClose,
    onGeneratePdf,
    open,
    projectTitle,
    projectScenarios,
    thresholds
  }: DwellingScenarioComparisonDialogProps
) {

  const theme = useTheme();

  const scenarios = projectScenarios.concat().sort((scenarioA, scenarioB) =>
    scenarioA.related_performances[0].building_energy_rating - scenarioB.related_performances[0].building_energy_rating
  ).reverse();

  interface TableRowType extends Record<string, number | string> {
    iconLink: string,
    title: string,
    category: string,
    current: number | string
  }

  const berRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-ber.svg`,
    title: "BER",
    category: "ber",
    current: dwelling.performance.rating
  };

  const capexRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-potential-grants.svg`,
    title: "Capex",
    category: "capex",
    current: ""
  };

  const upliftRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/arrow-up-black.svg`,
    title: "Uplift",
    category: "uplift",
    current: ""
  };

  const energyCreditsRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/nest_eco_leaf.png`,
    title: "Energy Credits [kWh]",
    category: "energy_credits",
    current: ""
  };

  const heatLossRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-heat-loss.svg`,
    title: "Heat loss indicator",
    category: "hli",
    current: dwelling.performance.heat_loss_parameter.toFixed(2)
  };

  const energyRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-annual-energy-bill.svg`,
    title: "Annual energy bill",
    category: "energyBill",
    current: Math.round(dwelling.performance.energy_cost)
  };

  const co2Row: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-co2-emissions.svg`,
    title: "Carbon dioxide emissions [kg/yr]",
    category: "co2",
    current: Math.round(dwelling.performance.total_co2)
  };

  const floorRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-floor.svg`,
    title: "Floor [W/m²K]",
    category: "floors",
    current: dwelling.performance.floors_weighted_average.toFixed(2)
  };

  const wallRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-external-wall.svg`,
    title: "External wall [W/m²K]",
    category: "walls",
    current: dwelling.performance.walls_weighted_average.toFixed(2)
  };

  const roofRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-roof.svg`,
    title: "Roof [W/m²K]",
    category: "roofs",
    current: dwelling.performance.roofs_weighted_average.toFixed(2)
  };

  const windowRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-windows.svg`,
    title: "Windows [W/m²K]",
    category: "windows",
    current: dwelling.performance.windows_weighted_average.toFixed(2)
  };

  const doorRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-door.svg`,
    title: "Doors [W/m²K]",
    category: "doors",
    current: dwelling.performance.doors_weighted_average.toFixed(2)
  };

  const ventilationRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-ventilation.svg`,
    title: "Ventilation",
    category: "ventilation",
    current: dwelling.system.ventilation_method
  };

  const mainHeatingRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-main-heating.svg`,
    title: "Main heating",
    category: "mainHeating",
    current: Math.round(dwelling.system.hs_main_system_efficiency)
  };

  const secondaryRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-secondary-heating.svg`,
    title: "Secondary heating",
    category: "secondaryHeating",
    current: Math.round(dwelling.system.hs_suppl_system_eff)
  };

  const heatingControlsRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-heating-controls.svg`,
    title: "Heating controls",
    category: "heatingControls",
    current: dwelling.system.space_heating_heat_system_control_cat
  };

  const waterHeatingRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-water-heating.svg`,
    title: "Water heating",
    category: "waterHeating",
    current: Math.round(dwelling.system.wh_main_system_eff)
  };

  const lightingRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-lighting.svg`,
    title: "Lighting",
    category: "lighting",
    current: Math.round(dwelling.system.low_energy_fixed_lighting)
  };

  const draughtsRow: TableRowType = {
    iconLink: `static/public/images/housing_upgrade_plans/icon-draughts.svg`,
    title: "Draughts [ac/h]",
    category: "draughts",
    current: dwelling.performance.adjusted_infiltration_rate.toFixed(2)
  };

  // const preliminariesRow: TableRowType = {
  //   iconLink: "/static/public/images/housing_upgrade_plans/icon-draughts.svg",
  //   title: "Preliminaries",
  //   category: "preliminaries",
  //   current: dwelling.performance.adjusted_infiltration_rate
  // };
  
  const dwellingInfoDescription = {
    color: theme.palette.text.secondary
  }
  
  const greenBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: ".5rem",
    backgroundColor: "#2EB32E",
    color: "#ffffff"
  }
  
  const orangeBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: ".5rem",
    backgroundColor: "#E38800",
    color: "#ffffff"
  }
  
  const redBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: ".5rem",
    backgroundColor: "#FF1C1C",
    color: "#ffffff"
  }

  const tableColumns: Array<Column<TableRowType>> = [
    {
      title: "",
      field: "iconLink",
      align: "center",
      width: 50,
      sorting: false,
      render: rowData => <RetroKitIcon 
        iconLink={rowData.iconLink} 
      />
    },
    {
      title: "",
      field: "title",
      type: "string",
      width: 250,
      sorting: false
    },
    {
      title: "Current",
      field: "current",
      type: "string",
      align: "center",
      width: 175,
      sorting: false,
      render: rowData => {
        if (rowData.category === "ber") {
          return <span>
                  {rowData.current}<img 
            style={{
              height: "12px"
            }}
                                        src={`${process.env.REACT_APP_API_BASE_URL || ""}/static/public/images/housing_upgrade_plans/ber-${rowData.current}.svg`}
                                        alt="BER chevron"
          />
                </span>;
        }
        if (rowData.category === "hli") {
          if (Number(rowData.current) <= thresholds.hli_green) {
            return <span 
              style={greenBox}
            >
                {rowData.current}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData.current}
            </span>;
        }
        if (rowData.category === "energyBill") {
          if (Number(rowData.current) <= thresholds.annual_energy_green) {
            return <span 
              style={greenBox}
            >
                €{rowData.current}
              </span>;
          }
          if (Number(rowData.current) <= thresholds.annual_energy_orange) {
            return <span 
              style={orangeBox}
            >
                €{rowData.current}
              </span>;
          }
          return <span 
            style={redBox}
          >
              €{rowData.current}
            </span>;
        }
        if (rowData.category === "co2") {
          if (Number(rowData.current) <= thresholds.carbon_emissions_green) {
            return <span 
              style={greenBox}
            >
                {rowData.current}
              </span>;
          }
          if (Number(rowData.current) <= thresholds.carbon_emissions_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData.current}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData.current}
            </span>;
        }
        if (rowData.category === "floors") {
          if (Number(rowData.current) <= thresholds.floors_weighted_average_green) {
            return <span 
              style={greenBox}
            >
                {rowData.current}
              </span>;
          }
          if (Number(rowData.current) <= thresholds.floors_weighted_average_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData.current}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData.current}
            </span>;
        }
        if (rowData.category === "walls") {
          if (Number(rowData.current) <= thresholds.walls_weighted_average_green) {
            return <span 
              style={greenBox}
            >
                {rowData.current}
              </span>;
          }
          if (Number(rowData.current) <= thresholds.walls_weighted_average_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData.current}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData.current}
            </span>;
        }
        if (rowData.category === "roofs") {
          if (Number(rowData.current) <= thresholds.roofs_weighted_average_green) {
            return <span 
              style={greenBox}
            >
                {rowData.current}
              </span>;
          }
          if (Number(rowData.current) <= thresholds.roofs_weighted_average_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData.current}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData.current}
            </span>;
        }
        if (rowData.category === "windows") {
          if (Number(rowData.current) <= thresholds.windows_weighted_average_green) {
            return <span 
              style={greenBox}
            >
                {rowData.current}
              </span>;
          }
          if (Number(rowData.current) <= thresholds.windows_weighted_average_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData.current}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData.current}
            </span>;
        }
        if (rowData.category === "doors") {
          if (Number(rowData.current) <= thresholds.doors_weighted_average_green) {
            return <span 
              style={greenBox}
            >
                {rowData.current}
              </span>;
          }
          if (Number(rowData.current) <= thresholds.doors_weighted_average_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData.current}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData.current}
            </span>;
        }
        if (rowData.category === "ventilation") {
          let method = "Mechanical";

          if (rowData.current === 1) {
            method = "Natural";
          }

          if (rowData.current !== thresholds.ventilation_method_green) {
            return <span 
              style={greenBox}
            >
                {method}
              </span>;
          }
          if (rowData.current === thresholds.ventilation_method_orange && dwelling.system.ventilation_no_of_chimneys <= thresholds.ventilation_no_of_chimneys_orange) {
            return <span 
              style={orangeBox}
            >
                {method}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {method}
            </span>;
        }
        if (rowData.category === "mainHeating") {
          if (Number(rowData.current) > thresholds.hs_main_system_efficiency_green) {
            return <span 
              style={greenBox}
            >
                {rowData.current}%
              </span>;
          }
          if (Number(rowData.current) > thresholds.hs_main_system_efficiency_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData.current}%
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData.current}%
            </span>;
        }
        if (rowData.category === "secondaryHeating") {
          if (dwelling.system.hs_suppl_system_eff > 0) {
            if (Number(rowData.current) > thresholds.hs_suppl_system_eff_green) {
              return <span 
                style={greenBox}
              >
                {rowData.current}%
              </span>;
            }
            if (Number(rowData.current) > thresholds.hs_suppl_system_eff_orange) {
              return <span 
                style={orangeBox}
              >
                {rowData.current}%
              </span>;
            }
            return <span 
              style={redBox}
            >
              {rowData.current}%
            </span>;
          }
          return <span 
            style={greenBox}
          >
              N/A
            </span>;
        }
        if (rowData.category === "heatingControls") {
          if (Number(rowData.current) >= thresholds.space_heating_heat_system_control_cat_green) {
            return <span 
              style={greenBox}
            >
                Level 3
              </span>;
          }
          if (Number(rowData.current) === thresholds.space_heating_heat_system_control_cat_orange) {
            return <span 
              style={orangeBox}
            >
                Level 2
              </span>;
          }
          return <span 
            style={redBox}
          >
              Level 1
            </span>;
        }
        if (rowData.category === "waterHeating") {
          if (Number(rowData.current) > thresholds.wh_main_system_eff_green) {
            return <span 
              style={greenBox}
            >
                {rowData.current}%
              </span>;
          }
          if (Number(rowData.current) > thresholds.wh_main_system_eff_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData.current}%
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData.current}%
            </span>;
        }
        if (rowData.category === "lighting") {
          if (Number(rowData.current) >= thresholds.low_energy_fixed_lighting_green) {
            return <span 
              style={greenBox}
            >
                {rowData.current}%
              </span>;
          }
          if (Number(rowData.current) >= thresholds.low_energy_fixed_lighting_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData.current}%
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData.current}%
            </span>;
        }
        if (rowData.category === "draughts") {
          if (Number(rowData.current) <= thresholds.adjusted_infiltration_rate_green) {
            return <span 
              style={greenBox}
            >
                {rowData.current}
              </span>;
          }
          if (Number(rowData.current) <= thresholds.adjusted_infiltration_rate_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData.current}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData.current}
            </span>;
        }
        return rowData.current;
      }

    }
  ];

  scenarios.forEach((_scenario, index) => {

    const scenarioKey = `scenario${index}`;

    const relatedPerformanceIndex = scenarios[index].related_performances.findIndex(
      (scenarioPerformance) => scenarioPerformance.dwelling === dwelling.id
    );

    const recommendedSystemIndex = scenarios[index].recommended_systems.findIndex(
      (scenarioSystem) => scenarioSystem.dwelling === dwelling.id
    );

    const relatedPerformance = scenarios[index].related_performances[relatedPerformanceIndex];
    const recommendedSystem = scenarios[index].recommended_systems[recommendedSystemIndex];

    berRow[scenarioKey] = relatedPerformance.rating;
    capexRow[scenarioKey] = Math.round(
      (
        relatedPerformance.fixed_costs +
        relatedPerformance.variable_costs +
        relatedPerformance.maintenance_costs +
        relatedPerformance.preliminaries_total
      ) * thresholds.vat_multiplier
    );
    upliftRow[scenarioKey] = Math.round(relatedPerformance.building_energy_rating);
    energyCreditsRow[scenarioKey] = Math.round(relatedPerformance.energy_credits);
    heatLossRow[scenarioKey] = relatedPerformance.heat_loss_parameter.toFixed(2);
    energyRow[scenarioKey] = Math.round(relatedPerformance.energy_cost);
    co2Row[scenarioKey] = Math.round(relatedPerformance.total_co2);
    floorRow[scenarioKey] = relatedPerformance.floors_weighted_average.toFixed(2);
    wallRow[scenarioKey] = relatedPerformance.walls_weighted_average.toFixed(2);
    roofRow[scenarioKey] = relatedPerformance.roofs_weighted_average.toFixed(2);
    windowRow[scenarioKey] = relatedPerformance.windows_weighted_average.toFixed(2);
    doorRow[scenarioKey] = relatedPerformance.doors_weighted_average.toFixed(2);
    ventilationRow[scenarioKey] = recommendedSystem.ventilation_method;
    mainHeatingRow[scenarioKey] = Math.round(recommendedSystem.hs_main_system_efficiency);
    secondaryRow[scenarioKey] = Math.round(recommendedSystem.hs_suppl_system_eff);
    heatingControlsRow[scenarioKey] = recommendedSystem.space_heating_heat_system_control_cat;
    waterHeatingRow[scenarioKey] = Math.round(recommendedSystem.wh_main_system_eff);
    lightingRow[scenarioKey] = Math.round(recommendedSystem.low_energy_fixed_lighting);
    draughtsRow[scenarioKey] = relatedPerformance.adjusted_infiltration_rate.toFixed(2);
    // preliminariesRow[scenarioKey] = relatedPerformance.

    tableColumns.push({
      title: scenarios[index].title,
      field: scenarioKey,
      type: "string",
      align: "center",
      width: 175,
      sorting: false,
      render: rowData => {
        if (rowData.category === "ber") {
          return <span>
                  {rowData[scenarioKey]}<img 
            style={{
              height: "12px"
            }}
                                             src={`${process.env.REACT_APP_API_BASE_URL || ""}/static/public/images/housing_upgrade_plans/ber-${rowData[scenarioKey]}.svg`}
                                             alt="BER chevron"
          />
                </span>;
        }

        if (rowData.category === "capex") {
          return <span>
                  €{rowData[scenarioKey]}
                </span>;
        }
        if (rowData.category === "uplift") {

          const uplift = Math.round(
            dwelling.performance.building_energy_rating - Number(rowData[scenarioKey])
          );

          if (uplift >= thresholds.uplift_green) {
            return <span 
              style={greenBox}
            >
                {uplift}
              </span>;
          }
          if (uplift > 0 && uplift < thresholds.uplift_green) {
            return <span 
              style={orangeBox}
            >
                {uplift}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {uplift}
            </span>;
        }
        if (rowData.category === "energy_credits") {
          const energy_credits = rowData[scenarioKey]

          if (Number(energy_credits) >= thresholds.uplift_green) {
            return <span
                style={greenBox}
            >
                {energy_credits}
              </span>;
          }
          if (Number(energy_credits) > 0 && Number(energy_credits) < thresholds.uplift_green) {
            return <span
                style={orangeBox}
            >
                {energy_credits}
              </span>;
          }
          return <span
              style={redBox}
          >
              {energy_credits}
            </span>;

            }
        if (rowData.category === "hli") {

          if (Number(rowData[scenarioKey]) <= thresholds.hli_green) {
            return <span 
              style={greenBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData[scenarioKey]}
            </span>;
        }
        if (rowData.category === "energyBill") {
          if (Number(rowData[scenarioKey]) <= thresholds.annual_energy_green) {
            return <span 
              style={greenBox}
            >
                €{rowData[scenarioKey]}
              </span>;
          }
          if (Number(rowData[scenarioKey]) <= thresholds.annual_energy_orange) {
            return <span 
              style={orangeBox}
            >
                €{rowData[scenarioKey]}
              </span>;
          }
          return <span 
            style={redBox}
          >
              €{rowData[scenarioKey]}
            </span>;
        }
        if (rowData.category === "co2") {
          if (Number(rowData[scenarioKey]) <= thresholds.carbon_emissions_green) {
            return <span 
              style={greenBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          if (Number(rowData[scenarioKey]) <= thresholds.carbon_emissions_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData[scenarioKey]}
            </span>;
        }
        if (rowData.category === "floors") {
          if (Number(rowData[scenarioKey]) <= thresholds.floors_weighted_average_green) {
            return <span 
              style={greenBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          if (Number(rowData[scenarioKey]) <= thresholds.floors_weighted_average_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData[scenarioKey]}
            </span>;
        }
        if (rowData.category === "walls") {
          if (Number(rowData[scenarioKey]) <= thresholds.walls_weighted_average_green) {
            return <span 
              style={greenBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          if (Number(rowData[scenarioKey]) <= thresholds.walls_weighted_average_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData[scenarioKey]}
            </span>;
        }
        if (rowData.category === "roofs") {
          if (Number(rowData[scenarioKey]) <= thresholds.roofs_weighted_average_green) {
            return <span 
              style={greenBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          if (Number(rowData[scenarioKey]) <= thresholds.roofs_weighted_average_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData[scenarioKey]}
            </span>;
        }
        if (rowData.category === "windows") {
          if (Number(rowData[scenarioKey]) <= thresholds.windows_weighted_average_green) {
            return <span 
              style={greenBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          if (Number(rowData[scenarioKey]) <= thresholds.windows_weighted_average_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData[scenarioKey]}
            </span>;
        }
        if (rowData.category === "doors") {
          if (Number(rowData[scenarioKey]) <= thresholds.doors_weighted_average_green) {
            return <span 
              style={greenBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          if (Number(rowData[scenarioKey]) <= thresholds.doors_weighted_average_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData[scenarioKey]}
            </span>;
        }
        if (rowData.category === "ventilation") {
          let method = "Mechanical";

          if (rowData[scenarioKey] === 1) {
            method = "Natural";
          }

          if (rowData[scenarioKey] !== thresholds.ventilation_method_green) {
            return <span 
              style={greenBox}
            >
                {method}
              </span>;
          }
          if (rowData[scenarioKey] === thresholds.ventilation_method_orange && dwelling.system.ventilation_no_of_chimneys <= thresholds.ventilation_no_of_chimneys_orange) {
            return <span 
              style={orangeBox}
            >
                {method}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {method}
            </span>;
        }
        if (rowData.category === "mainHeating") {
          if (Number(rowData[scenarioKey]) > thresholds.hs_main_system_efficiency_green) {
            return <span 
              style={greenBox}
            >
                {rowData[scenarioKey]}%
              </span>;
          }
          if (Number(rowData[scenarioKey]) > thresholds.hs_main_system_efficiency_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData[scenarioKey]}%
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData[scenarioKey]}%
            </span>;
        }
        if (rowData.category === "secondaryHeating") {
          if (dwelling.system.hs_suppl_system_eff > 0) {
            if (Number(rowData[scenarioKey]) > thresholds.hs_suppl_system_eff_green) {
              return <span 
                style={greenBox}
              >
                {rowData[scenarioKey]}%
              </span>;
            }
            if (Number(rowData[scenarioKey]) > thresholds.hs_suppl_system_eff_orange) {
              return <span 
                style={orangeBox}
              >
                {rowData[scenarioKey]}%
              </span>;
            }
            return <span 
              style={redBox}
            >
              {rowData[scenarioKey]}%
            </span>;
          }
          return <span 
            style={greenBox}
          >
              N/A
            </span>;
        }
        if (rowData.category === "heatingControls") {
          if (Number(rowData[scenarioKey]) >= thresholds.space_heating_heat_system_control_cat_green) {
            return <span 
              style={greenBox}
            >
                Level 3
              </span>;
          }
          if (rowData[scenarioKey] === thresholds.space_heating_heat_system_control_cat_orange) {
            return <span 
              style={orangeBox}
            >
                Level 2
              </span>;
          }
          return <span 
            style={redBox}
          >
              Level 1
            </span>;
        }
        if (rowData.category === "waterHeating") {
          if (Number(rowData[scenarioKey]) > thresholds.wh_main_system_eff_green) {
            return <span 
              style={greenBox}
            >
                {rowData[scenarioKey]}%
              </span>;
          }
          if (Number(rowData[scenarioKey]) > thresholds.wh_main_system_eff_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData[scenarioKey]}%
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData[scenarioKey]}%
            </span>;
        }
        if (rowData.category === "lighting") {
          if (Number(rowData[scenarioKey]) >= thresholds.low_energy_fixed_lighting_green) {
            return <span 
              style={greenBox}
            >
                {rowData[scenarioKey]}%
              </span>;
          }
          if (Number(rowData[scenarioKey]) >= thresholds.low_energy_fixed_lighting_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData[scenarioKey]}%
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData[scenarioKey]}%
            </span>;
        }
        if (rowData.category === "draughts") {
          if (Number(rowData[scenarioKey]) <= thresholds.adjusted_infiltration_rate_green) {
            return <span 
              style={greenBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          if (Number(rowData[scenarioKey]) <= thresholds.adjusted_infiltration_rate_orange) {
            return <span 
              style={orangeBox}
            >
                {rowData[scenarioKey]}
              </span>;
          }
          return <span 
            style={redBox}
          >
              {rowData[scenarioKey]}
            </span>;
        }
        return rowData[scenarioKey];
      }
    });
  });

  const tableRows: Array<TableRowType> = [
    berRow, capexRow, upliftRow, energyCreditsRow, heatLossRow, energyRow, co2Row, floorRow,
    wallRow, roofRow, windowRow, doorRow, ventilationRow, mainHeatingRow,
    secondaryRow, heatingControlsRow, waterHeatingRow, lightingRow, draughtsRow
  ];

  const handleClose = useCallback(() => {
      onClose();
    },
    [onClose]
  );

  const handleGeneratePdf = useCallback(() => {
      onGeneratePdf(dwelling);
    },
    [dwelling, onGeneratePdf]
  );

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={handleClose}
      scroll="body"
      PaperComponent={DialogPaperComponent}
    >

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "8px",
          paddingBottom: "8px",
          paddingLeft: "24px",
          paddingRight: "8px"
        }}
      >

        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          variant="h5"
        >
          Compare scenarios in {projectTitle}
        </Typography>

        <Box
          sx={{
            flex: 1
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          
          <Tooltip
            title="Generate PDF"
            aria-label="generate-pdf"
          >
            
            <IconButton
              onClick={handleGeneratePdf}
            >
              
              <PdfIcon
                sx={{
                  color: theme.retrokitPalette.green.dark
                }}
              />
              
            </IconButton>
            
          </Tooltip>
          
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          <Tooltip
            title="Close"
            aria-label="pdf-close-modal"
          >
            <IconButton
              onClick={handleClose}
            >
              
              <CloseIcon />
              
            </IconButton>
            
          </Tooltip>
          
        </Box>

      </Box>

      <Box
        sx={{
          width: "100%",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
          padding: 0,
          display: "flex",
          flexDirection: "column",
          marginTop: 0
        }}
      >

        <Paper
          sx={{
            display: "flex",
            padding: "0px 16px",
            minHeight: "48px"
          }}
          elevation={0}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              margin: "20px 0"
            }}
          >

            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                paddingLeft: "48px",
                paddingRight: "48px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  paddingRight: "16px",
                  justifyContent: "center"
                }}
              >
                <Typography
                  sx={dwellingInfoDescription}
                >
                  MPRN: {dwelling.mprn}
                </Typography>

                {
                  dwelling.street !== "None" && 
                  dwelling.street !== "" ? <Typography
                    sx={dwellingInfoDescription}
                  >
                    {dwelling.street},
                  </Typography> : null
                }

                {
                  dwelling.area !== "None" && 
                  dwelling.area !== "" ? <Typography
                    sx={dwellingInfoDescription}
                  >
                    {dwelling.area},
                  </Typography> : null
                }

                {
                  dwelling.city !== "None" && 
                  dwelling.city !== "" ? <Typography
                    sx={dwellingInfoDescription}
                  >
                    {dwelling.city},
                  </Typography> : null
                }

                {
                  dwelling.county !== "None" && 
                  dwelling.county !== "" ? <Typography
                    sx={dwellingInfoDescription}
                  >
                    {dwelling.county},
                  </Typography> : null
                }

                {
                  dwelling.postcode !== "None" && 
                  dwelling.postcode !== "" ? <Typography
                    sx={dwellingInfoDescription}
                  >
                    {dwelling.postcode}
                  </Typography> : null
                }
                
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flex: 1
                }}
              >
                <DwellingMapImage
                  dwelling={dwelling}
                  width={359}
                  height={144}
                />
              </Box>
              
            </Box>
            
          </Box>
          
        </Paper>

        <MaterialTable
          columns={tableColumns}
          data={tableRows}
          isLoading={isLoading}
          localization={{
            toolbar: {
              nRowsSelected: "{0} (s) selected"
            },
            body: {
              emptyDataSourceMessage: "No dwellings to display",
              filterRow: {
                filterTooltip: "Filter"
              }
            }
          }}
          options={{
            draggable: false,
            fixedColumns: {
              left: 3,
              right: 1
            },
            headerStyle: {
              backgroundColor: theme.palette.primary.main,
              color: "#ffffff",
              textAlign: "center",
              fontWeight: "bold",
              whiteSpace: "nowrap"
            },
            padding: "dense",
            paging: false,
            search: false,
            selection: false,
            rowStyle: (_rowData, index) => {
              if (index % 2) {
                return {
                  backgroundColor: "#EEEEEE",
                  whiteSpace: "nowrap"
                };
              }
              return {
                whiteSpace: "nowrap"
              };
            },
            tableLayout: "fixed",
            toolbar: false
          }}
        />
        
      </Box>

      <DialogActions
        sx={{
          paddingRight: "24px"
        }}
      >
        
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        
      </DialogActions>

    </Dialog>
  );
}
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import MaterialTable, {
  type Column,
  MTableToolbar
} from "@material-table/core";

import {
  Close as CloseIcon,
  Groups as GroupsIcon,
  Person as PersonIcon,
  Search as SearchIcon
} from "@mui/icons-material";
import { colors, TablePagination, Zoom } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { BERChevron } from "common/components/BERChevron";
import { DWELLING_TABLE_COLUMNS } from "common/constants/tables";
import {
  useAddDwellingsToProjectDialog
} from "features/projects/hooks/useAddDwellingsToProjectDialog";

import type { Dwelling } from "features/dwellings/types";
import type { MinimalProjectDwelling } from "features/projects/types";


interface AddProjectDwellingsModalProps {
  projectId: number;
  projectTitle: string;
  open: boolean;
  onClose: () => void;
  onAddDwellings: (dwellings: Array<Dwelling | MinimalProjectDwelling>) => void;
}


export function AddDwellingsToProjectDialog(
  {
    projectId,
    projectTitle,
    open,
    onClose,
    onAddDwellings
  }: AddProjectDwellingsModalProps
) {

  const theme = useTheme();

  const tableRef = useRef();

  const [
    searchText,
    setSearchText
  ] = useState<string>();

  const [
    pageSize,
    setPageSize
  ] = useState<number>(5);

  const [
    pageNumber,
    setPageNumber
  ] = useState<number>(0);

  const [
    orderByField,
    setOrderByField
  ] = useState<string>("id");

  const [
    orderDirection,
    setOrderDirection
  ] = useState<string>("desc");

  const {
    getDwellingsNotInProject,
    dwellingsNotInProjectIsLoading,
    dwellingsNotInProject,
    totalCount
  } = useAddDwellingsToProjectDialog();

  const [
    selectedDwellings,
    setSelectedDwellings
  ] = useState<MinimalProjectDwelling[]>(
    []
  );

  const handleClose = useCallback(() => {
    setSelectedDwellings([]);
    onClose();
  }, [onClose, setSelectedDwellings]);

  const handleOnAddDwellingsClicked = useCallback(
    (dwellingArray: Array<MinimalProjectDwelling>) => {
      onAddDwellings(dwellingArray);
      handleClose();
    },
    [handleClose, onAddDwellings]
  );

  useEffect(
    () => {
      getDwellingsNotInProject({
        id: projectId,
        page: pageNumber + 1,
        size: pageSize,
        search: searchText,
        orderByField,
        orderDirection
      });
    }, [
      getDwellingsNotInProject,
      orderByField,
      orderDirection,
      pageNumber,
      pageSize,
      projectId,
      searchText
    ]
  );

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  const tableColumns: Array<Column<MinimalProjectDwelling>> = useMemo(
    () => [
      ...DWELLING_TABLE_COLUMNS,
      {
        title: "Type",
        field: "type",
        align: "center",
        width: 50,
        cellStyle: {
          padding: "0 16px 0 16px"
        },
        render: (rowData) => {
          if (rowData.type === "individual") {
            return <Tooltip
              title="Individual heating scheme"
              arrow
              TransitionComponent={Zoom}
            >
              <Avatar
                sx={{
                  backgroundColor: "#FFFFFF",
                  width: "36px",
                  height: "36px"
                }}
                aria-label="avatar-individual"
              >
                <PersonIcon style={{ color: theme.retrokitPalette.indigo.main }} />
              </Avatar>
            </Tooltip>;
          }
          return <Tooltip
            title="Group heating scheme"
            arrow
            TransitionComponent={Zoom}
          >
            <Avatar
              sx={{
                backgroundColor: theme.retrokitPalette.indigo.main,
                width: "36px",
                height: "36px"
              }}
              aria-label="avatar-group"
            >
              <GroupsIcon style={{ color: "#FFFFFF" }} />
            </Avatar>
          </Tooltip>;
        }
      },
      {
        title: "Rating",
        field: "rating",
        width: 50,
        cellStyle: {
          textAlign: "center",
          padding: "0 16px 0 16px"
        },
        render: (rowData) => <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <BERChevron
            rating={rowData.performance.rating}
          />
        </Box>
      }
    ],
    [
      theme.retrokitPalette.indigo.main
    ]
  );

  const TablePaperComponent = useCallback(
    (props: JSX.IntrinsicAttributes & PaperProps) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      {...props}
    />,
    []
  );

  const HideTableToolbarComponent = useCallback(
    (props: JSX.IntrinsicAttributes & PaperProps) => <Box
      sx={{
        display: "none",
        height: "0px"
      }}
    >
      <MTableToolbar
        {...props}
      />
    </Box>, []
  );

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      scroll="body"
      PaperComponent={DialogPaperComponent}
    >

      <Box
        sx={{
          width: "100%",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
          padding: "0px"
        }}
      >
        <Box
          sx={{
            backgroundColor: selectedDwellings.length > 0 ? "#FCE4EC" : "#FFFFFF"
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: selectedDwellings.length > 0 ? theme.palette.secondary.main : theme.palette.text.primary,
                  marginBottom: 0
                }}
              >
                {
                  selectedDwellings.length > 0 ?
                    `${selectedDwellings.length} ${selectedDwellings.length === 1 ? "dwelling" : "dwellings"} selected` :
                    `Add dwellings to ${projectTitle}`
                }
              </Typography>

              {
                selectedDwellings.length > 0 ? <Tooltip
                  title="Deselect"
                  placement="top"
                >
                  <IconButton
                    type="button"
                    sx={{
                      p: "10px"
                    }}
                  >
                    <CloseIcon
                      color="secondary"
                      onClick={
                        () => {
                          if (tableRef.current) {
                            // @ts-expect-error tableRef.current type is unknown
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                            tableRef.current.onAllSelected(false);
                            setSelectedDwellings([]);
                          }
                        }
                      }
                    />
                  </IconButton>
                </Tooltip> : null
              }
            </Box>

            <Box
              sx={{
                flex: 1
              }}
            />

            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center"
              }}
              elevation={0}
            >
              <IconButton
                sx={{
                  p: "10px"
                }}
              >
                <SearchIcon />
              </IconButton>

              <InputBase
                placeholder="Search"
                value={searchText}
                onChange={
                  (event) => {
                    setSearchText(event.target.value);
                    setPageNumber(0);
                  }
                }
              />

              <IconButton
                type="button"
                sx={{
                  p: "10px"
                }}
              >
                <CloseIcon
                  onClick={
                    () => {
                      setSearchText("");
                      setPageNumber(0);
                    }
                  }
                />
              </IconButton>

            </Paper>

          </Toolbar>

        </Box>

        <MaterialTable
          tableRef={tableRef}
          columns={tableColumns}
          components={{
            Container: TablePaperComponent,
            Toolbar: HideTableToolbarComponent
          }}
          data={
            // NOTE: This map function is required to preserve selections
            // after performing searches
            dwellingsNotInProject.map(
              (dwelling) => {
                if (
                  selectedDwellings.find(
                    selectedDwelling => selectedDwelling.id === dwelling.id
                  )
                ) {
                  return {
                    ...dwelling,
                    tableData: {
                      checked: true
                    }
                  };
                }
                return dwelling;
              }
            )
          }
          isLoading={dwellingsNotInProjectIsLoading}
          onOrderCollectionChange={
            (newOrderByCollection) => {
              if (newOrderByCollection.length === 1) {
                setOrderByField(newOrderByCollection[0].orderByField);
                setOrderDirection(newOrderByCollection[0].orderDirection);
              }
            }
          }
          localization={{
            body: {
              emptyDataSourceMessage: "No dwellings found",
              filterRow: {
                filterTooltip: "Filter"
              }
            }
          }}
          options={{
            headerSelectionProps: {
              style: {
                padding: "0px"
              }
            },
            headerStyle: {
              backgroundColor: colors.blue["500"],
              color: theme.palette.common.white,
              padding: "0 16px 0 16px",
              fontWeight: "bold"
            },
            padding: "dense",
            paging: false,
            rowStyle: (rowData, index) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (rowData.tableData.checked === true) {
                return {
                  backgroundColor: "#fce4ec",
                  color: theme.palette.secondary.main,
                  fontWeight: "bold",
                  fontSize: 14
                };
              }
              if (index % 2) {
                return {
                  backgroundColor: colors.indigo["100"],
                  fontSize: 14
                };
              }
              return {
                backgroundColor: colors.indigo["50"],
                fontSize: 14
              };
            },
            search: false,
            selection: true,
            selectionProps: {
              style: {
                padding: "0 16px 0 16px"
              }
            },
            showTitle: false,
            tableLayout: "fixed"
          }}
          onSelectionChange={
            (rows) => {
              const selectedDwellingsNotCurrentlyShowing = selectedDwellings.filter(
                selectedDwelling => dwellingsNotInProject.find(
                  dwelling => dwelling.id === selectedDwelling.id
                ) === undefined
              );
              setSelectedDwellings(
                [...selectedDwellingsNotCurrentlyShowing, ...rows]
              );
            }
          }
        />

        <Box>
          <TablePagination
            component="div"
            count={totalCount}
            page={pageNumber}
            onPageChange={(event, newPageNumber) => {
              setPageNumber(newPageNumber);
            }}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onRowsPerPageChange={(event) => {
              setPageSize(Number(event.target.value));
            }}
          />
        </Box>

      </Box>

      <DialogActions
        sx={{
          paddingRight: "24px"
        }}
      >

        <Button
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleOnAddDwellingsClicked(selectedDwellings)}
          disabled={selectedDwellings.length === 0}
        >
          Add to Project
        </Button>

      </DialogActions>
    </Dialog>
  );
}
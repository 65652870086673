import React, { useCallback, useEffect, useRef, useState } from "react";


import {
  colors
} from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { BEREdited } from "common/components/BEREdited";
import { BERScaleBeforeAndAfter } from "common/components/BERScaleBeforeAndAfter";
import iconTitleHomeBlank from "common/icons/icon-title-home-blank.png";
import {
  useHomeEnergyAssessment
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Dwelling } from "features/dwellings/types";
import type { MinimalProjectDwelling } from "features/projects/types";


interface HomeEnergyAssessmentDialogContent5Props {
  dwelling: Dwelling | MinimalProjectDwelling;
  contentBackgroundColour: string;
  pageBackgroundColour: string;
  scenarioRating: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File,
    field: string
  ) => void;
}


export function DialogContentPlan(
  {
    dwelling,
    contentBackgroundColour,
    pageBackgroundColour,
    scenarioRating,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent5Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingOverallPlan,
    setSectionHeadingOverallPlan
  ] = useState<string>("");

  const [
    sectionHeadingOverallPlanError,
    setSectionHeadingOverallPlanError
  ] = useState<boolean>(false);

  const [
    sectionHeadingOverallPlanLength,
    setSectionHeadingOverallPlanLength
  ] = useState<number>(0);

  const sectionHeadingOverallPlanLengthLimit = 100;

  const sectionHeadingOverallPlanInputRef = useRef<HTMLInputElement>();

  const [
    overallPlanParagraph1,
    setOverallPlanParagraph1
  ] = useState<string>("");

  const [
    overallPlanParagraph1Error,
    setOverallPlanParagraph1Error
  ] = useState<boolean>(false);

  const [
    overallPlanParagraph1Length,
    setOverallPlanParagraph1Length
  ] = useState<number>(0);

  const overallPlanParagraph1LengthLimit = 500;

  const overallPlanParagraph1InputRef = useRef<HTMLInputElement>();

  const [
    overallPlanParagraph2,
    setOverallPlanParagraph2
  ] = useState<string>("");

  const [
    overallPlanParagraph2Error,
    setOverallPlanParagraph2Error
  ] = useState<boolean>(false);

  const [
    overallPlanParagraph2Length,
    setOverallPlanParagraph2Length
  ] = useState<number>(0);

  const overallPlanParagraph2LengthLimit = 250;

  const [
    overallPlanParagraph3,
    setOverallPlanParagraph3
  ] = useState<string>("");

  const [
    overallPlanParagraph3Error,
    setOverallPlanParagraph3Error
  ] = useState<boolean>(false);

  const [
    overallPlanParagraph3Length,
    setOverallPlanParagraph3Length
  ] = useState<number>(0);

  const overallPlanParagraph3LengthLimit = 250;

  const theme = useTheme();

  useEffect(
    () => {

      if (homeEnergyAssessment !== undefined) {

        setSectionHeadingOverallPlan(
          homeEnergyAssessment.section_heading_overall_plan
        );

        setSectionHeadingOverallPlanLength(
          homeEnergyAssessment.section_heading_overall_plan !== null ?
            homeEnergyAssessment.section_heading_overall_plan.length : 0
        );

        setOverallPlanParagraph1(
          homeEnergyAssessment.overall_plan_paragraph_1
        );

        setOverallPlanParagraph1Length(
          homeEnergyAssessment.overall_plan_paragraph_1 !== null ?
            homeEnergyAssessment.overall_plan_paragraph_1.length : 0
        );

        setOverallPlanParagraph2(
          homeEnergyAssessment.overall_plan_paragraph_2
        );

        setOverallPlanParagraph2Length(
          homeEnergyAssessment.overall_plan_paragraph_2 !== null ?
            homeEnergyAssessment.overall_plan_paragraph_2.length : 0
        );

        setOverallPlanParagraph3(
          homeEnergyAssessment.overall_plan_paragraph_3
        );

        setOverallPlanParagraph3Length(
          homeEnergyAssessment.overall_plan_paragraph_3 !== null ?
            homeEnergyAssessment.overall_plan_paragraph_3.length : 0
        );
      }
    },
    [
      homeEnergyAssessment
    ]
  );

  useEffect(
    () => {

      if (
        overallPlanParagraph1Error ||
        overallPlanParagraph2Error ||
        overallPlanParagraph3Error ||
        sectionHeadingOverallPlanError
      ) {
        setInputError(true);
      }

      if (inputError) {
        if (
          !overallPlanParagraph1Error &&
          !overallPlanParagraph2Error &&
          !overallPlanParagraph3Error &&
          !sectionHeadingOverallPlanError
        ) {
          setInputError(false);
        }
      }

    },
    [
      overallPlanParagraph1Error,
      overallPlanParagraph2Error,
      overallPlanParagraph3Error,
      sectionHeadingOverallPlanError,
      inputError,
      setInputError
    ]
  );

  const handleFieldBlur = useCallback(
    (value: string | number | File, field: string) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconTitleHomeBlank}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingOverallPlanError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingOverallPlan}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldBlur(
                  event.target.value,
                  "section_heading_overall_plan"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingOverallPlanLength(value.length);
              if (value.length > sectionHeadingOverallPlanLengthLimit) {
                setSectionHeadingOverallPlanError(true);
              } else if (sectionHeadingOverallPlanError) {
                setSectionHeadingOverallPlanError(false);
              }
              setSectionHeadingOverallPlan(value);
            }}
            helperText={
              sectionHeadingOverallPlanLengthLimit - sectionHeadingOverallPlanLength <= (sectionHeadingOverallPlanLengthLimit / 2) ?
                `${sectionHeadingOverallPlanLength}/${sectionHeadingOverallPlanLengthLimit}` : ""
            }
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                if (!inputError) {
                  if (
                    overallPlanParagraph1InputRef !== undefined &&
                    overallPlanParagraph1InputRef.current !== undefined
                  ) {
                    overallPlanParagraph1InputRef.current.focus()
                  }
                }
              }
            }}
            inputRef={sectionHeadingOverallPlanInputRef}
          />

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >

          <BERScaleBeforeAndAfter
            beforeRating={dwelling.performance.rating}
            afterRating={scenarioRating}
          />

        </Box>

      </Box>

      {
        dwelling.performance.ber_edited ? <BEREdited/> : null
      }

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={overallPlanParagraph1Error}
          label="Summary"
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={overallPlanParagraph1}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldBlur(
                event.target.value,
                "overall_plan_paragraph_1"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target;
            setOverallPlanParagraph1Length(value.length);
            if (value.length > overallPlanParagraph1LengthLimit) {
              setOverallPlanParagraph1Error(true);
            } else if (overallPlanParagraph1Error) {
              setOverallPlanParagraph1Error(false);
            }
            setOverallPlanParagraph1(value);
          }}
          helperText={
            overallPlanParagraph1LengthLimit - overallPlanParagraph1Length <= (overallPlanParagraph1LengthLimit / 2) ?
              `${overallPlanParagraph1Length}/${overallPlanParagraph1LengthLimit}` : ""
          }
          inputRef={overallPlanParagraph1InputRef}
        />

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <Tooltip
          title={
            "This field is not currently editable. We're working on it."
          }
        >
          <TextField
            disabled
            error={overallPlanParagraph2Error}
            label="Future state"
            sx={{
              backgroundColor: pageBackgroundColour,
              width: "100%"
            }}
            multiline
            size="small"
            variant="outlined"
            InputProps={{
              sx: {
                backgroundColor: theme.palette.common.white
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right"
              }
            }}
            value={overallPlanParagraph2}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldBlur(
                  event.target.value,
                  "overall_plan_paragraph_2"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setOverallPlanParagraph2Length(value.length);
              if (value.length > overallPlanParagraph2LengthLimit) {
                setOverallPlanParagraph2Error(true);
              } else if (overallPlanParagraph2Error) {
                setOverallPlanParagraph2Error(false);
              }
              setOverallPlanParagraph2(value);
            }}
            helperText={
              overallPlanParagraph2LengthLimit - overallPlanParagraph2Length <= (overallPlanParagraph2LengthLimit / 2) ?
                `${overallPlanParagraph2Length}/${overallPlanParagraph2LengthLimit}` : ""
            }
          />

        </Tooltip>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={overallPlanParagraph3Error}
          label="Additional futher state"
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={overallPlanParagraph3}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldBlur(
                event.target.value,
                "overall_plan_paragraph_3"
              );
            }
          }}
          onChange={(event) => {

            const {
              value
            } = event.target;

            setOverallPlanParagraph3Length(value.length);

            if (value.length > overallPlanParagraph3LengthLimit) {
              setOverallPlanParagraph3Error(true);
            } else if (overallPlanParagraph3Error) {
              setOverallPlanParagraph3Error(false);
            }
            setOverallPlanParagraph3(value);
          }}
          helperText={
            overallPlanParagraph3LengthLimit - overallPlanParagraph3Length <= (overallPlanParagraph3LengthLimit / 2) ?
              `${overallPlanParagraph3Length}/${overallPlanParagraph3LengthLimit}` : ""
          }
        />

      </Box>

    </DialogContent>
  );
}
import { useEffect, useState } from "react";

import {
  useGetProjectByIdQuery,
  useSuperhomesExportMutation,
  useUpdateProjectDetailsMutation,
  useUpdateProjectDwellingsMutation,
  useUpdateProjectScenarioRecommendationMutation
} from "features/projects/api/projectsApi";
import {
  useGetCustomScenariosNotInProjectQuery,
  useGetDefaultScenariosNotInProjectQuery,
  useGetProjectScenariosQuery,
  useUpdateProjectCustomScenariosMutation,
  useUpdateProjectDefaultScenariosMutation,
  useUpdateProjectScenariosMutation
} from "features/projectScenarios/api/projectScenariosApi";

import type { CustomScenario } from "features/customScenarios/types";
import type { DefaultScenario } from "features/defaultScenarios/types";
import type { ProjectScenario } from "features/projectScenarios/types";



export function useProjectView(projectId: number) {
  const [
    isLoading,
    setIsLoading
  ] = useState(false);

  const [
    projectScenarios,
    setProjectScenarios
  ] = useState<Array<ProjectScenario>>([]);

  const [
    customScenariosNotInProject,
    setCustomScenariosNotInProject
  ] = useState<Array<CustomScenario>>([]);

  const [
    defaultScenariosNotInProject,
    setDefaultScenariosNotInProject
  ] = useState<Array<DefaultScenario>>([]);

  const {
    isLoading: isGetProjectLoading,
    data: project
  } = useGetProjectByIdQuery({ id: projectId });

  const {
    isLoading: isGetCustomScenariosNotInProjectLoading,
    data: getCustomScenariosNotInProjectResult
  } = useGetCustomScenariosNotInProjectQuery({
    id: projectId,
    inProject: false
  });

  const {
    isLoading: isGetDefaultScenariosNotInProjectLoading,
    data: getDefaultScenariosNotInProjectResult
  } = useGetDefaultScenariosNotInProjectQuery({
    id: projectId,
    inProject: false
  });

  const {
    isLoading: isGetProjectScenariosLoading,
    data: getProjectScenariosResult
  } = useGetProjectScenariosQuery({ id: projectId });

  const [updateProjectDwelling] = useUpdateProjectDwellingsMutation();

  const [updateProjectScenarios] = useUpdateProjectScenariosMutation();

  const [updateProjectDetails] = useUpdateProjectDetailsMutation();

  const [
    updateProjectCustomScenarios
  ] = useUpdateProjectCustomScenariosMutation();

  const [
    updateProjectDefaultScenarios
  ] = useUpdateProjectDefaultScenariosMutation();

  const [
    superhomesExport
  ] = useSuperhomesExportMutation();

  const [
    updateProjectScenarioRecommendation
  ] = useUpdateProjectScenarioRecommendationMutation();

  // Update loading state
  useEffect(() => {
    setIsLoading(
      isGetProjectScenariosLoading ||
      isGetCustomScenariosNotInProjectLoading ||
      isGetDefaultScenariosNotInProjectLoading
    );
  }, [
    isGetProjectLoading,
    isGetProjectScenariosLoading,
    isGetCustomScenariosNotInProjectLoading,
    isGetDefaultScenariosNotInProjectLoading
  ]);

  // Update project scenarios
  useEffect(() => {
    setProjectScenarios(getProjectScenariosResult ?? []);
  }, [
    getProjectScenariosResult
  ]);

  // Update custom scenarios not in project
  useEffect(() => {
    setCustomScenariosNotInProject(
      getCustomScenariosNotInProjectResult ?? []
    );
  }, [
    getCustomScenariosNotInProjectResult
  ]);

  // Update default scenarios not in project
  useEffect(() => {
    setDefaultScenariosNotInProject(
      getDefaultScenariosNotInProjectResult ?? []
    );
  }, [
    getDefaultScenariosNotInProjectResult
  ]);

  return {
    customScenariosNotInProject,
    defaultScenariosNotInProject,
    isLoading,
    project,
    projectScenarios,
    superhomesExport,
    updateProjectCustomScenarios,
    updateProjectDefaultScenarios,
    updateProjectDetails,
    updateProjectDwelling,
    updateProjectScenarios,
    updateProjectScenarioRecommendation
  };
}

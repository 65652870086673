import React, { useCallback, useEffect, useRef, useState } from "react";


import {
  colors,
} from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { BEREdited } from "common/components/BEREdited";
import {
  DwellingAndScenarioPerformanceBanner
} from "common/components/DwellingAndScenarioPerformanceBanner";
import iconBetterForOurPlanet from "common/icons/icon-better-for-planet.png"
import iconHealthierHome from "common/icons/icon-house-type.png"
import iconIncreasedValue from "common/icons/icon-money-saved.png"
import iconReducedBills from "common/icons/icon-reduced-bills.png"
import iconTitleKeyObjectives from "common/icons/icon-title-key-objectives.png"
import iconWarmerHome from "common/icons/icon-warmer-home.png"
import { 
  useHomeEnergyAssessment 
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Dwelling } from "features/dwellings/types";
import type { MinimalProjectDwelling } from "features/projects/types";


interface HomeEnergyAssessmentDialogContent2Props {
  dwelling: Dwelling | MinimalProjectDwelling;
  contentBackgroundColour: string;
  scenarioDetail: string;
  scenarioRating: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File,
    field: string
  ) => void;
}


export function DialogContentObjectives(
  {
    dwelling,
    contentBackgroundColour,
    scenarioDetail,
    scenarioRating,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent2Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingKeyObjectives,
    setSectionHeadingKeyObjectives
  ] = useState<string>('');
  
  const [
    sectionHeadingKeyObjectivesError,
    setSectionHeadingKeyObjectivesError
  ] = useState<boolean>(false);

  const [
    sectionHeadingKeyObjectivesLength,
    setSectionHeadingKeyObjectivesLength
  ] = useState<number>(0);

  const sectionHeadingKeyObjectivesLengthLimit = 100;

  const sectionHeadingKeyObjectivesInputRef = useRef<HTMLInputElement>();

  const [
    keyObjectivesParagraph,
    setKeyObjectivesParagraph
  ] = useState<string>('');
  
  const [
    keyObjectivesParagraphError,
    setKeyObjectivesParagraphError
  ] = useState<boolean>(false);

  const [
    keyObjectivesParagraphLength,
    setKeyObjectivesParagraphLength
  ] = useState<number>(0);

  const keyObjectivesParagraphLengthLimit = 475;

  const keyObjectivesParagraphInputRef = useRef<HTMLInputElement>();

  const theme = useTheme();

  useEffect(
    () => {

      if (
        keyObjectivesParagraphError ||
        sectionHeadingKeyObjectivesError
      ) {
        setInputError(true)
      }

      if (inputError) {
        if (
          !keyObjectivesParagraphError &&
          !sectionHeadingKeyObjectivesError
        ) {
          setInputError(false)
        }
      }

    },
    [
      keyObjectivesParagraphError,
      sectionHeadingKeyObjectivesError,
      inputError,
      setInputError
    ]
  );

  useEffect(
    () => {

      if (homeEnergyAssessment !== undefined) {
        
        setSectionHeadingKeyObjectives(
          homeEnergyAssessment.section_heading_key_objectives
        )
        
        setSectionHeadingKeyObjectivesLength(
          homeEnergyAssessment.section_heading_key_objectives !== null ?
            homeEnergyAssessment.section_heading_key_objectives.length : 0
        )
        
        setKeyObjectivesParagraph(
          homeEnergyAssessment.key_objectives_paragraph
        )
        
        setKeyObjectivesParagraphLength(
          homeEnergyAssessment.key_objectives_paragraph !== null ?
            homeEnergyAssessment.key_objectives_paragraph.length : 0
        )
      }
    },
    [
      homeEnergyAssessment
    ]
  );

  const handleFieldUpdated = useCallback(
    (
      value: string | number | File,
      field: string
    ) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconTitleKeyObjectives}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingKeyObjectivesError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingKeyObjectives}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldUpdated(
                  event.target.value,
                  "section_heading_key_objectives"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingKeyObjectivesLength(value.length);
              if (value.length > sectionHeadingKeyObjectivesLengthLimit) {
                setSectionHeadingKeyObjectivesError(true);
              } else if (sectionHeadingKeyObjectivesError) {
                  setSectionHeadingKeyObjectivesError(false);
                }
              setSectionHeadingKeyObjectives(value)
            }}
            helperText={
              sectionHeadingKeyObjectivesLengthLimit - sectionHeadingKeyObjectivesLength <= (sectionHeadingKeyObjectivesLengthLimit / 2) ?
                `${sectionHeadingKeyObjectivesLength}/${sectionHeadingKeyObjectivesLengthLimit}` : ""
            }
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                if (!inputError) {
                  if (
                    keyObjectivesParagraphInputRef !== undefined &&
                    keyObjectivesParagraphInputRef.current !== undefined
                  ) {
                    keyObjectivesParagraphInputRef.current.focus()
                  }
                }
              }
            }}
            inputRef={sectionHeadingKeyObjectivesInputRef}
          />

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <DwellingAndScenarioPerformanceBanner
          dwellingDetail={String(dwelling.mprn)}
          dwellingRating={dwelling.performance.rating}
          scenarioDetail={scenarioDetail}
          scenarioRating={scenarioRating}
        />

      </Box>

      {
        dwelling.performance.ber_edited ? <BEREdited/> : null
      }
      
      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px",
            gap: "16px"
          }}
        >
          <Box
            sx={{
              flex: 0.2,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#6e9b2f",
              borderRadius: "20px",
              padding: "8px 16px 8px 16px",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center"
            }}
          >

            <img
              src={iconReducedBills}
              alt="Icon"
              style={{
                height: "48px",
                maxWidth: "48px"
              }}
            />

            <Typography
              sx={{
                color: theme.palette.common.white,
                textAlign: 'center'
              }}
            >
              Reduced bills
            </Typography>

          </Box>

          <Box
            sx={{
              flex: 0.2,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#6e9b2f",
              borderRadius: "20px",
              padding: "8px 16px 8px 16px",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center"
            }}
          >

            <img
              src={iconHealthierHome}
              alt="Icon"
              style={{
                height: "48px",
                maxWidth: "48px"
              }}
            />

            <Typography
              sx={{
                color: theme.palette.common.white,
                textAlign: 'center'
              }}
            >
              Healthier home
            </Typography>

          </Box>

          <Box
            sx={{
              flex: 0.2,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#6e9b2f",
              borderRadius: "20px",
              padding: "8px 16px 8px 16px",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center"
            }}
          >

            <img
              src={iconWarmerHome}
              alt="Icon"
              style={{
                height: "48px",
                maxWidth: "48px"
              }}
            />

            <Typography
              sx={{
                color: theme.palette.common.white,
                textAlign: 'center'
              }}
            >
              Warmer home
            </Typography>

          </Box>

          <Box
            sx={{
              flex: 0.2,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#6e9b2f",
              borderRadius: "20px",
              padding: "8px 16px 8px 16px",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center"
            }}
          >

            <img
              src={iconBetterForOurPlanet}
              alt="Icon"
              style={{
                height: "48px",
                maxWidth: "48px"
              }}
            />

            <Typography
              sx={{
                color: theme.palette.common.white,
                textAlign: 'center'
              }}
            >
              Better for our planet
            </Typography>

          </Box>

          <Box
            sx={{
              flex: 0.2,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#6e9b2f",
              borderRadius: "20px",
              padding: "8px 16px 8px 16px",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center"
            }}
          >

            <img
              src={iconIncreasedValue}
              alt="Icon"
              style={{
                height: "48px",
                maxWidth: "48px"
              }}
            />

            <Typography
              sx={{
                color: theme.palette.common.white,
                textAlign: 'center'
              }}
            >
              Increased value
            </Typography>

          </Box>

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px",
          display: "flex",
          flexDirection: "row"
        }}
      >
        <TextField
          error={keyObjectivesParagraphError}
          label="Key Objectives"
          sx={{
            backgroundColor: contentBackgroundColour,
            width: "100%",
            flex: 1
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={keyObjectivesParagraph}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "key_objectives_paragraph"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target
            setKeyObjectivesParagraphLength(value.length)
            if (value.length > keyObjectivesParagraphLengthLimit) {
              setKeyObjectivesParagraphError(true)
            }
            else if (keyObjectivesParagraphError) {
                setKeyObjectivesParagraphError(false)
              }
            setKeyObjectivesParagraph(value)
          }}
          helperText={
            keyObjectivesParagraphLengthLimit - keyObjectivesParagraphLength <= (keyObjectivesParagraphLengthLimit / 2) ?
              `${keyObjectivesParagraphLength}/${keyObjectivesParagraphLengthLimit}` : ''
          }
          inputRef={keyObjectivesParagraphInputRef}
        />

       {/* <Box
          sx={{
            paddingLeft: "8px"
          }}
        >
          <Tooltip
            title="This field supports HTML text tags like <b>TEXT</b> for bold text"
          >
            <InfoIcon
              sx={{
                color: blue[500]
              }}
            />
          </Tooltip>
        </Box> */}

      </Box>

    </DialogContent>
  );
}